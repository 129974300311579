import { PublicControllerApi as LaunchpadPublicControllerApi, UserControllerApi as LaunchpadUserControllerApi } from './generated/launchpad-api';
import { BASE_PATH as LaunchpadBasePath } from './generated/launchpad-api/base';

import { PublicControllerApi as CounselMePublicControllerApi, AuthenticatedControllerApi as CounselMeAuthenticatedControllerApi, LawyerControllerApi, CompanyControllerApi } from './generated/counselme-api'
import { BASE_PATH as CounselMeBasePath } from './generated/counselme-api/base';

import axios from 'axios'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export class Environment {

    private static instance: Environment;

    private _launchpadPublicControllerApi: LaunchpadPublicControllerApi
    private _launchpadUserControllerApi: LaunchpadUserControllerApi

    private _counselMePublicControllerApi: CounselMePublicControllerApi
    private _counselMeAuthenticatedApi: CounselMeAuthenticatedControllerApi
    private _counselMeLawyerControllerApi: LawyerControllerApi
    private _counselMeCompanyControllerApi: CompanyControllerApi

    private _parentUrl: string

    private _counselMeBasePath: string

    private constructor(parentUrl: string, launchpadBasePath: string, counselMeBasePath: string) {
        this._launchpadPublicControllerApi = new LaunchpadPublicControllerApi(undefined, launchpadBasePath, axios)
        this._launchpadUserControllerApi = new LaunchpadUserControllerApi(undefined, launchpadBasePath, axios)

        this._counselMePublicControllerApi = new CounselMePublicControllerApi(undefined, counselMeBasePath, axios)
        this._counselMeAuthenticatedApi = new CounselMeAuthenticatedControllerApi(undefined, counselMeBasePath, axios)
        this._counselMeLawyerControllerApi = new LawyerControllerApi(undefined, counselMeBasePath, axios)
        this._counselMeCompanyControllerApi = new CompanyControllerApi(undefined, counselMeBasePath, axios)
        
        this._parentUrl = parentUrl

        this._counselMeBasePath = counselMeBasePath;
    }


    public get launchpadPublicControllerApi() {
        return this._launchpadPublicControllerApi;
    }

    public get launchpadUserControllerApi() {
        return this._launchpadUserControllerApi;
    }

    public get counselMePublicControllerApi() {
        return this._counselMePublicControllerApi;
    }

    public get counselMeAuthenticatedApi() {
        return this._counselMeAuthenticatedApi;
    }

    public get counselMeLawyerControllerApi() {
        return this._counselMeLawyerControllerApi;
    }

    public get counselMeCompanyControllerApi() {
        return this._counselMeCompanyControllerApi;
    }

    public get counselMeBasePath() {
        return this._counselMeBasePath;
    }

    public get parentUrl() {
        return this._parentUrl;
    }

    private initSentry = (sentryDNS: string, sentryEnvironment: string, version: string) => {
        Sentry.init({
            dsn: sentryDNS,
            environment: sentryEnvironment,
            release: version,
            integrations: [new BrowserTracing()],
            tracesSampleRate: 1.0,
        });
    }

    public static async getInstance(): Promise<Environment> {
        if (!Environment.instance) {
            return axios.get("/config")
                .then((result) => {
                    Environment.instance = new Environment(result.data.PARENT_URL, result.data.LAUNCHPAD_API_GATEWAY + "/launchpad", result.data.COUNSELME_API_GATEWAY + "/counselme");

                    if (result.data.SENTRY_DSN && result.data.SENTRY_ENVIRONMENT && process.env.VERSION) {
                        Environment.instance.initSentry(result.data.SENTRY_DSN, result.data.SENTRY_ENVIRONMENT, process.env.VERSION)
                    }

                    return Environment.instance;
                })
                .catch(() => {
                    Environment.instance = new Environment('http://admin.dev-your-domain.com:8084', LaunchpadBasePath, CounselMeBasePath);
                    return Environment.instance;
                })
        }

        return Environment.instance;
    }
}