import React from 'react';

import ReactDOM from 'react-dom/client';
import './index.css';
import App from '../src/App/App';
import reportWebVitals from './reportWebVitals';
import { Environment } from './Environment';
import theme from './theme'
import { BrowserRouter } from "react-router-dom";
import locale_de from "./translations/de.json";
import locale_en from "./translations/en.json";
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { IntlProvider } from 'react-intl';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const languages = new Map([
  ["de", locale_de],
  ["en", locale_en]
]);

const language = navigator.language.split(/[-_]/)[0];


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// initialize environment singleton
Environment.getInstance()

root.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <IntlProvider locale={language} messages={languages.get(language)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LocalizationProvider>
      </IntlProvider>
    </MuiThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
