import React from 'react';

import axios from 'axios'

import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

type ErrorHandlerProps = {
  parentURL: string | undefined,
}

const ErrorHandler = ({ parentURL }: ErrorHandlerProps) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  // authentication error handing
  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    if (parentURL && window.parent) {
      window.parent.postMessage({ type: "error", content: JSON.stringify(error?.response) }, parentURL);
    } else {
      setOpen(true)
      setMessage(error.toString())
    }

    return Promise.reject(error);;
  });

  return (
    <Snackbar open={open} autoHideDuration={6000} onAbort={() => setOpen(false)} onClose={() => setOpen(false)}>
      <div>
        <MuiAlert elevation={6} variant="filled" severity='error'>{message}</MuiAlert>
      </div>
    </Snackbar>
  );
}

export default ErrorHandler;