import { createTheme } from '@mui/material/styles';
import './fonts.css'

const theme = createTheme({
  kodo: {
    primaryColor: '#002444',
    secondaryColor: '#104a7e',
    thirdColor: '#a8c5d8',
    fourthColor: '#e6f1f9',
    fifthColor: '#FF4252',
  },
  typography: {
    fontFamily: ['"Helvetica Neue LT W05 65 Medium"', 'helvetica', 'Arial'].join(','),
    h1: {
      fontFamily: ['"Helvetica Neue LT W05_77 Bd Cn"', 'helvetica', 'Arial'].join(','),
      fontSize: '3rem',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    h2: {
      fontFamily: ['"Helvetica Neue LT W05_77 Bd Cn"', 'helvetica', 'Arial'].join(','),
      fontSize: '2.5rem',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    h3: {
      fontFamily: ['"Helvetica Neue LT W05_77 Bd Cn"', 'helvetica', 'Arial'].join(','),
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h4: {
      fontFamily: ['"Helvetica Neue LT W05_47 Lt Cn"', 'helvetica', 'Arial'].join(','),
    },
    h5: {
      fontFamily: ['"Helvetica Neue LT W05 75 Bold"', 'helvetica', 'Arial'].join(','),
      fontWeight: 'bold',
    },
    body1: {
      fontFamily: ['"Helvetica Neue LT W05_47 Lt Cn"', 'helvetica', 'Arial'].join(','),
      flexWrap: 'wrap',
    },
    body2: {
      fontFamily: ['"Helvetica Neue LT W05_47 Lt Cn"', 'helvetica','Arial'].join(','),
      flexWrap: 'wrap',
    },
    button: {
      fontFamily: ['"Helvetica Neue LT W05_77 Bd Cn"', 'helvetica', 'Arial'].join(','),
      fontWeight: 'bold',
    },
  },
  palette: {
    primary: {
      main: '#E2A412'
    },
    secondary: {
      main: '#BC3143'
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: { backgroundColor: "#E2A412" },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "#CCCCCC",
          color: 'black',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          fontWeight: 'bold',
          fontFamily: ['"Helvetica Neue LT W05 75 Bold"', 'helvetica', 'Arial'].join(','),
        },
      },
    },



    
  },
});

Object.assign(theme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        width: "100%",
        height: "100%",
        display: "flex",
        overflow: "hidden",

      },
      container: {
        margin: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column"
      },
      toolbar: {
        flexShrink: 0,
      },
      placeHolder: {
        position: "static"
      },
      editor: {
        flexGrow: 1,
        overflowY: "auto",
        borderBottom: "1px solid gray",
      }
    }
  }
})

export default theme;




/*Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        width: "100%",
        height: "100%",
        display: "flex",
        overflow: "hidden",

      },
      container: {
        margin: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column"
      },
      toolbar: {
        flexShrink: 0,
      },
      placeHolder: {
        position: "static"
      },
      editor: {
        flexGrow: 1,
        overflowY: "auto",
        borderBottom: "1px solid gray",
      }
    }
  }
});*/