import React, { Suspense, VoidFunctionComponent } from 'react';

import ReactLoading from "react-loading"
import { FormattedMessage } from 'react-intl'
import { Grid } from "@mui/material"
import { MeResource } from '../generated/counselme-api';

export type LaunchpadComponentProps = {
  isAuthenticated: boolean,
  me: MeResource | undefined,
  yourFunction: () => void,
}

type ComponentProps = {
  component: React.LazyExoticComponent<(props: LaunchpadComponentProps) => JSX.Element>
}

type RootProps = ComponentProps & LaunchpadComponentProps


const Root = (props: RootProps) => {

  const TestComponent = props.component

  return (
      <Suspense fallback={
        <Grid container textAlign={'center'} alignSelf={'center'} alignItems={'center'} alignContent={'center'} justifySelf={'center'} justifyItems={'center'} justifyContent={'center'}>
          <Grid item>
            <ReactLoading type={'bars'} color={'#BC3143'} height={60} width={60} />
            <FormattedMessage id="root.loading" />
          </Grid>
        </Grid>}>
      <TestComponent {...props}/>
      </Suspense>
  );
}

export default Root
