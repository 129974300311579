/* tslint:disable */
/* eslint-disable */
/**
 * Identity Service API
 * Documentation Launchpad Service API v1.0
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BasicAuthCredentialsResource
 */
export interface BasicAuthCredentialsResource {
    /**
     * 
     * @type {number}
     * @memberof BasicAuthCredentialsResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BasicAuthCredentialsResource
     */
    'user'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicAuthCredentialsResource
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface ClientAuthenticationParametersResource
 */
export interface ClientAuthenticationParametersResource {
    /**
     * 
     * @type {number}
     * @memberof ClientAuthenticationParametersResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientAuthenticationParametersResource
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAuthenticationParametersResource
     */
    'clientPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAuthenticationParametersResource
     */
    'clientScope'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAuthenticationParametersResource
     */
    'redirectUrl'?: string;
}
/**
 * 
 * @export
 * @interface ConfigurationResource
 */
export interface ConfigurationResource {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationResource
     */
    'gitUserName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationResource
     */
    'gitPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationResource
     */
    'helmRepository'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationResource
     */
    'terraformRepository'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationResource
     */
    'doToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationResource
     */
    'springCloudConfigRepository'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationResource
     */
    'launchpadApiGateway'?: string;
}
/**
 * 
 * @export
 * @interface DeploymentResource
 */
export interface DeploymentResource {
    /**
     * 
     * @type {ProjectResource}
     * @memberof DeploymentResource
     */
    'project'?: ProjectResource;
    /**
     * 
     * @type {string}
     * @memberof DeploymentResource
     */
    'logs'?: string;
}
/**
 * 
 * @export
 * @interface EnvironmentVariableResource
 */
export interface EnvironmentVariableResource {
    /**
     * 
     * @type {number}
     * @memberof EnvironmentVariableResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentVariableResource
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentVariableResource
     */
    'value'?: string;
    /**
     * 
     * @type {SecretResource}
     * @memberof EnvironmentVariableResource
     */
    'secret'?: SecretResource;
    /**
     * 
     * @type {ParameterResource}
     * @memberof EnvironmentVariableResource
     */
    'parameter'?: ParameterResource;
}
/**
 * 
 * @export
 * @interface IdentityResource
 */
export interface IdentityResource {
    /**
     * 
     * @type {number}
     * @memberof IdentityResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdentityResource
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityResource
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<RoleResource>}
     * @memberof IdentityResource
     */
    'roles'?: Array<RoleResource>;
}
/**
 * 
 * @export
 * @interface PageIdentityResource
 */
export interface PageIdentityResource {
    /**
     * 
     * @type {number}
     * @memberof PageIdentityResource
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageIdentityResource
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageIdentityResource
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageIdentityResource
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageIdentityResource
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Array<IdentityResource>}
     * @memberof PageIdentityResource
     */
    'content'?: Array<IdentityResource>;
    /**
     * 
     * @type {Sort}
     * @memberof PageIdentityResource
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageIdentityResource
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageIdentityResource
     */
    'last'?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageIdentityResource
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageIdentityResource
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageProjectResource
 */
export interface PageProjectResource {
    /**
     * 
     * @type {number}
     * @memberof PageProjectResource
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProjectResource
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProjectResource
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProjectResource
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProjectResource
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Array<ProjectResource>}
     * @memberof PageProjectResource
     */
    'content'?: Array<ProjectResource>;
    /**
     * 
     * @type {Sort}
     * @memberof PageProjectResource
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageProjectResource
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProjectResource
     */
    'last'?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageProjectResource
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageProjectResource
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageableObject
     */
    'sort'?: Sort;
}
/**
 * 
 * @export
 * @interface ParameterResource
 */
export interface ParameterResource {
    /**
     * 
     * @type {number}
     * @memberof ParameterResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParameterResource
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParameterResource
     */
    'value'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterResource
     */
    'canDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface PrivilegeResource
 */
export interface PrivilegeResource {
    /**
     * 
     * @type {number}
     * @memberof PrivilegeResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PrivilegeResource
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ProjectResource
 */
export interface ProjectResource {
    /**
     * 
     * @type {number}
     * @memberof ProjectResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectResource
     */
    'name'?: string;
    /**
     * 
     * @type {Array<RoleResource>}
     * @memberof ProjectResource
     */
    'roles'?: Array<RoleResource>;
    /**
     * 
     * @type {Array<PrivilegeResource>}
     * @memberof ProjectResource
     */
    'privileges'?: Array<PrivilegeResource>;
    /**
     * 
     * @type {string}
     * @memberof ProjectResource
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ServiceResource>}
     * @memberof ProjectResource
     */
    'services'?: Array<ServiceResource>;
    /**
     * 
     * @type {number}
     * @memberof ProjectResource
     */
    'versionMajor'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectResource
     */
    'versionMinor'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectResource
     */
    'versionPatch'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectResource
     */
    'owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResource
     */
    'adminUrl'?: string;
    /**
     * 
     * @type {PrivilegeResource}
     * @memberof ProjectResource
     */
    'adminPrivilege'?: PrivilegeResource;
    /**
     * 
     * @type {RoleResource}
     * @memberof ProjectResource
     */
    'adminRole'?: RoleResource;
}
/**
 * 
 * @export
 * @interface RoleResource
 */
export interface RoleResource {
    /**
     * 
     * @type {number}
     * @memberof RoleResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleResource
     */
    'name'?: string;
    /**
     * 
     * @type {Array<PrivilegeResource>}
     * @memberof RoleResource
     */
    'privileges'?: Array<PrivilegeResource>;
    /**
     * 
     * @type {boolean}
     * @memberof RoleResource
     */
    'public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoleResource
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface SecretResource
 */
export interface SecretResource {
    /**
     * 
     * @type {number}
     * @memberof SecretResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SecretResource
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof SecretResource
     */
    'value'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SecretResource
     */
    'canDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface ServiceResource
 */
export interface ServiceResource {
    /**
     * 
     * @type {number}
     * @memberof ServiceResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceResource
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceResource
     */
    'host'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceResource
     */
    'description'?: string;
    /**
     * 
     * @type {Array<VolumeResource>}
     * @memberof ServiceResource
     */
    'volumes'?: Array<VolumeResource>;
    /**
     * 
     * @type {Array<ServiceResource>}
     * @memberof ServiceResource
     */
    'dependencies'?: Array<ServiceResource>;
    /**
     * 
     * @type {number}
     * @memberof ServiceResource
     */
    'replicaCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceResource
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceResource
     */
    'image'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceResource
     */
    'port'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceResource
     */
    'containerPort'?: number;
    /**
     * 
     * @type {Array<ParameterResource>}
     * @memberof ServiceResource
     */
    'parameters'?: Array<ParameterResource>;
    /**
     * 
     * @type {Array<EnvironmentVariableResource>}
     * @memberof ServiceResource
     */
    'environmentVariables'?: Array<EnvironmentVariableResource>;
    /**
     * 
     * @type {Array<SecretResource>}
     * @memberof ServiceResource
     */
    'secrets'?: Array<SecretResource>;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceResource
     */
    'canDelete'?: boolean;
    /**
     * 
     * @type {BasicAuthCredentialsResource}
     * @memberof ServiceResource
     */
    'basicAuthCredentials'?: BasicAuthCredentialsResource;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceResource
     */
    'redirectWWW'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceResource
     */
    'webFrontend'?: boolean;
    /**
     * 
     * @type {ClientAuthenticationParametersResource}
     * @memberof ServiceResource
     */
    'clientAuthenticationParameters'?: ClientAuthenticationParametersResource;
    /**
     * 
     * @type {string}
     * @memberof ServiceResource
     */
    'corsLevel'?: ServiceResourceCorsLevelEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceResource
     */
    'oAuthComponent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceResource
     */
    'oauthComponent'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ServiceResourceCorsLevelEnum {
    None = 'NONE',
    Project = 'PROJECT',
    All = 'ALL'
}

/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface VolumeResource
 */
export interface VolumeResource {
    /**
     * 
     * @type {number}
     * @memberof VolumeResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof VolumeResource
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VolumeResource
     */
    'mount'?: string;
    /**
     * 
     * @type {string}
     * @memberof VolumeResource
     */
    'size'?: string;
}

/**
 * AdminControllerApi - axios parameter creator
 * @export
 */
export const AdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationToken: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('confirmationToken', 'email', email)
            const localVarPath = `/admin/confirmationToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {string} [value] 
         * @param {number} [secretId] 
         * @param {number} [parameterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnvironmentVariable: async (serviceId: number, key: string, value?: string, secretId?: number, parameterId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('createEnvironmentVariable', 'serviceId', serviceId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('createEnvironmentVariable', 'key', key)
            const localVarPath = `/admin/service/{serviceId}/env`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }

            if (secretId !== undefined) {
                localVarQueryParameter['secret_id'] = secretId;
            }

            if (parameterId !== undefined) {
                localVarQueryParameter['parameter_id'] = parameterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {Array<number>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIdentity: async (email: string, password: string, roles: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('createIdentity', 'email', email)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('createIdentity', 'password', password)
            // verify required parameter 'roles' is not null or undefined
            assertParamExists('createIdentity', 'roles', roles)
            const localVarPath = `/admin/identity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }

            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParameter: async (serviceId: number, key: string, value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('createParameter', 'serviceId', serviceId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('createParameter', 'key', key)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('createParameter', 'value', value)
            const localVarPath = `/admin/service/{serviceId}/parameter`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {Array<string>} names 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrivileges: async (projectId: number, names: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createPrivileges', 'projectId', projectId)
            // verify required parameter 'names' is not null or undefined
            assertParamExists('createPrivileges', 'names', names)
            const localVarPath = `/admin/project/{projectId}/privileges`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (names) {
                localVarQueryParameter['names'] = names;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} description 
         * @param {string} owner 
         * @param {string} privilege 
         * @param {string} role 
         * @param {string} [adminUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (name: string, description: string, owner: string, privilege: string, role: string, adminUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('createProject', 'name', name)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('createProject', 'description', description)
            // verify required parameter 'owner' is not null or undefined
            assertParamExists('createProject', 'owner', owner)
            // verify required parameter 'privilege' is not null or undefined
            assertParamExists('createProject', 'privilege', privilege)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('createProject', 'role', role)
            const localVarPath = `/admin/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (adminUrl !== undefined) {
                localVarQueryParameter['adminUrl'] = adminUrl;
            }

            if (privilege !== undefined) {
                localVarQueryParameter['privilege'] = privilege;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} name 
         * @param {boolean} isPublic 
         * @param {Array<number>} privileges 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole: async (projectId: number, name: string, isPublic: boolean, privileges: Array<number>, description: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createRole', 'projectId', projectId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('createRole', 'name', name)
            // verify required parameter 'isPublic' is not null or undefined
            assertParamExists('createRole', 'isPublic', isPublic)
            // verify required parameter 'privileges' is not null or undefined
            assertParamExists('createRole', 'privileges', privileges)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('createRole', 'description', description)
            const localVarPath = `/admin/project/{projectId}/role`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (isPublic !== undefined) {
                localVarQueryParameter['isPublic'] = isPublic;
            }

            if (privileges) {
                localVarQueryParameter['privileges'] = privileges;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSecret: async (serviceId: number, key: string, value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('createSecret', 'serviceId', serviceId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('createSecret', 'key', key)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('createSecret', 'value', value)
            const localVarPath = `/admin/service/{serviceId}/secret`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} name 
         * @param {string} description 
         * @param {number} replicaCount 
         * @param {string} version 
         * @param {string} image 
         * @param {number} port 
         * @param {number} containerPort 
         * @param {boolean} redirectWWW 
         * @param {boolean} isWebFrontend 
         * @param {'NONE' | 'PROJECT' | 'ALL'} corsLevel 
         * @param {boolean} oauthComponent 
         * @param {Array<number>} [dependencies] 
         * @param {string} [host] 
         * @param {string} [basicAuthUser] 
         * @param {string} [basicAuthPassword] 
         * @param {string} [clientId] 
         * @param {string} [clientPassword] 
         * @param {string} [clientScope] 
         * @param {string} [clientRedirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createService: async (projectId: number, name: string, description: string, replicaCount: number, version: string, image: string, port: number, containerPort: number, redirectWWW: boolean, isWebFrontend: boolean, corsLevel: 'NONE' | 'PROJECT' | 'ALL', oauthComponent: boolean, dependencies?: Array<number>, host?: string, basicAuthUser?: string, basicAuthPassword?: string, clientId?: string, clientPassword?: string, clientScope?: string, clientRedirectUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createService', 'projectId', projectId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('createService', 'name', name)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('createService', 'description', description)
            // verify required parameter 'replicaCount' is not null or undefined
            assertParamExists('createService', 'replicaCount', replicaCount)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('createService', 'version', version)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('createService', 'image', image)
            // verify required parameter 'port' is not null or undefined
            assertParamExists('createService', 'port', port)
            // verify required parameter 'containerPort' is not null or undefined
            assertParamExists('createService', 'containerPort', containerPort)
            // verify required parameter 'redirectWWW' is not null or undefined
            assertParamExists('createService', 'redirectWWW', redirectWWW)
            // verify required parameter 'isWebFrontend' is not null or undefined
            assertParamExists('createService', 'isWebFrontend', isWebFrontend)
            // verify required parameter 'corsLevel' is not null or undefined
            assertParamExists('createService', 'corsLevel', corsLevel)
            // verify required parameter 'oauthComponent' is not null or undefined
            assertParamExists('createService', 'oauthComponent', oauthComponent)
            const localVarPath = `/admin/project/{projectId}/service`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (replicaCount !== undefined) {
                localVarQueryParameter['replicaCount'] = replicaCount;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (image !== undefined) {
                localVarQueryParameter['image'] = image;
            }

            if (port !== undefined) {
                localVarQueryParameter['port'] = port;
            }

            if (containerPort !== undefined) {
                localVarQueryParameter['containerPort'] = containerPort;
            }

            if (dependencies) {
                localVarQueryParameter['dependencies'] = dependencies;
            }

            if (host !== undefined) {
                localVarQueryParameter['host'] = host;
            }

            if (basicAuthUser !== undefined) {
                localVarQueryParameter['basicAuthUser'] = basicAuthUser;
            }

            if (basicAuthPassword !== undefined) {
                localVarQueryParameter['basicAuthPassword'] = basicAuthPassword;
            }

            if (redirectWWW !== undefined) {
                localVarQueryParameter['redirectWWW'] = redirectWWW;
            }

            if (isWebFrontend !== undefined) {
                localVarQueryParameter['isWebFrontend'] = isWebFrontend;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (clientPassword !== undefined) {
                localVarQueryParameter['clientPassword'] = clientPassword;
            }

            if (clientScope !== undefined) {
                localVarQueryParameter['clientScope'] = clientScope;
            }

            if (clientRedirectUrl !== undefined) {
                localVarQueryParameter['clientRedirectUrl'] = clientRedirectUrl;
            }

            if (corsLevel !== undefined) {
                localVarQueryParameter['corsLevel'] = corsLevel;
            }

            if (oauthComponent !== undefined) {
                localVarQueryParameter['oauthComponent'] = oauthComponent;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} name 
         * @param {string} size 
         * @param {string} mount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVolume: async (serviceId: number, name: string, size: string, mount: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('createVolume', 'serviceId', serviceId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('createVolume', 'name', name)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('createVolume', 'size', size)
            // verify required parameter 'mount' is not null or undefined
            assertParamExists('createVolume', 'mount', mount)
            const localVarPath = `/admin/service/{serviceId}/volume`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (mount !== undefined) {
                localVarQueryParameter['mount'] = mount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} envId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironmentVariable: async (envId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'envId' is not null or undefined
            assertParamExists('deleteEnvironmentVariable', 'envId', envId)
            const localVarPath = `/admin/env/{envId}`
                .replace(`{${"envId"}}`, encodeURIComponent(String(envId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} parameterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParameter: async (parameterId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parameterId' is not null or undefined
            assertParamExists('deleteParameter', 'parameterId', parameterId)
            const localVarPath = `/admin/parameter/{parameterId}`
                .replace(`{${"parameterId"}}`, encodeURIComponent(String(parameterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} secretId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSecret: async (secretId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'secretId' is not null or undefined
            assertParamExists('deleteSecret', 'secretId', secretId)
            const localVarPath = `/admin/secret/{secretId}`
                .replace(`{${"secretId"}}`, encodeURIComponent(String(secretId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteService: async (serviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('deleteService', 'serviceId', serviceId)
            const localVarPath = `/admin/service/{serviceId}`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} volumeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVolume: async (volumeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'volumeId' is not null or undefined
            assertParamExists('deleteVolume', 'volumeId', volumeId)
            const localVarPath = `/admin/volume/{volumeId}`
                .replace(`{${"volumeId"}}`, encodeURIComponent(String(volumeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdentitiesPaginated: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/identities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdentityByEmail: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('getIdentityByEmail', 'email', email)
            const localVarPath = `/admin/identity/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsPaginated: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} gitUserName 
         * @param {string} gitPassword 
         * @param {string} helmRepository 
         * @param {string} terraformRepository 
         * @param {string} doToken 
         * @param {string} springCloudConfigRepository 
         * @param {string} launchpadApiGateway 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putConfiguration: async (gitUserName: string, gitPassword: string, helmRepository: string, terraformRepository: string, doToken: string, springCloudConfigRepository: string, launchpadApiGateway: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gitUserName' is not null or undefined
            assertParamExists('putConfiguration', 'gitUserName', gitUserName)
            // verify required parameter 'gitPassword' is not null or undefined
            assertParamExists('putConfiguration', 'gitPassword', gitPassword)
            // verify required parameter 'helmRepository' is not null or undefined
            assertParamExists('putConfiguration', 'helmRepository', helmRepository)
            // verify required parameter 'terraformRepository' is not null or undefined
            assertParamExists('putConfiguration', 'terraformRepository', terraformRepository)
            // verify required parameter 'doToken' is not null or undefined
            assertParamExists('putConfiguration', 'doToken', doToken)
            // verify required parameter 'springCloudConfigRepository' is not null or undefined
            assertParamExists('putConfiguration', 'springCloudConfigRepository', springCloudConfigRepository)
            // verify required parameter 'launchpadApiGateway' is not null or undefined
            assertParamExists('putConfiguration', 'launchpadApiGateway', launchpadApiGateway)
            const localVarPath = `/admin/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (gitUserName !== undefined) {
                localVarQueryParameter['gitUserName'] = gitUserName;
            }

            if (gitPassword !== undefined) {
                localVarQueryParameter['gitPassword'] = gitPassword;
            }

            if (helmRepository !== undefined) {
                localVarQueryParameter['helmRepository'] = helmRepository;
            }

            if (terraformRepository !== undefined) {
                localVarQueryParameter['terraformRepository'] = terraformRepository;
            }

            if (doToken !== undefined) {
                localVarQueryParameter['doToken'] = doToken;
            }

            if (springCloudConfigRepository !== undefined) {
                localVarQueryParameter['springCloudConfigRepository'] = springCloudConfigRepository;
            }

            if (launchpadApiGateway !== undefined) {
                localVarQueryParameter['launchpadApiGateway'] = launchpadApiGateway;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} major 
         * @param {number} minor 
         * @param {number} patch 
         * @param {string} [cause] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVersion: async (projectId: number, major: number, minor: number, patch: number, cause?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('putVersion', 'projectId', projectId)
            // verify required parameter 'major' is not null or undefined
            assertParamExists('putVersion', 'major', major)
            // verify required parameter 'minor' is not null or undefined
            assertParamExists('putVersion', 'minor', minor)
            // verify required parameter 'patch' is not null or undefined
            assertParamExists('putVersion', 'patch', patch)
            const localVarPath = `/admin/project/{projectId}/version`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (major !== undefined) {
                localVarQueryParameter['major'] = major;
            }

            if (minor !== undefined) {
                localVarQueryParameter['minor'] = minor;
            }

            if (patch !== undefined) {
                localVarQueryParameter['patch'] = patch;
            }

            if (cause !== undefined) {
                localVarQueryParameter['cause'] = cause;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} identityId 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setIdentityRole: async (identityId: number, roleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identityId' is not null or undefined
            assertParamExists('setIdentityRole', 'identityId', identityId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('setIdentityRole', 'roleId', roleId)
            const localVarPath = `/admin/identity/{identityId}/roles`
                .replace(`{${"identityId"}}`, encodeURIComponent(String(identityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} privilegeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRolePrivilege: async (roleId: number, privilegeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('setRolePrivilege', 'roleId', roleId)
            // verify required parameter 'privilegeId' is not null or undefined
            assertParamExists('setRolePrivilege', 'privilegeId', privilegeId)
            const localVarPath = `/admin/role/{roleId}/privilege`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (privilegeId !== undefined) {
                localVarQueryParameter['privilegeId'] = privilegeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} envId 
         * @param {string} key 
         * @param {string} [value] 
         * @param {number} [secretId] 
         * @param {number} [parameterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnvironmentVariable: async (envId: number, key: string, value?: string, secretId?: number, parameterId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'envId' is not null or undefined
            assertParamExists('updateEnvironmentVariable', 'envId', envId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('updateEnvironmentVariable', 'key', key)
            const localVarPath = `/admin/env/{envId}`
                .replace(`{${"envId"}}`, encodeURIComponent(String(envId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }

            if (secretId !== undefined) {
                localVarQueryParameter['secret_id'] = secretId;
            }

            if (parameterId !== undefined) {
                localVarQueryParameter['parameter_id'] = parameterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} identityId 
         * @param {Array<number>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdentity: async (identityId: number, roles: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identityId' is not null or undefined
            assertParamExists('updateIdentity', 'identityId', identityId)
            // verify required parameter 'roles' is not null or undefined
            assertParamExists('updateIdentity', 'roles', roles)
            const localVarPath = `/admin/identity/{identityId}`
                .replace(`{${"identityId"}}`, encodeURIComponent(String(identityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} parameterId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParameter: async (parameterId: number, key: string, value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parameterId' is not null or undefined
            assertParamExists('updateParameter', 'parameterId', parameterId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('updateParameter', 'key', key)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('updateParameter', 'value', value)
            const localVarPath = `/admin/parameter/{parameterId}`
                .replace(`{${"parameterId"}}`, encodeURIComponent(String(parameterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} description 
         * @param {string} owner 
         * @param {string} [adminUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (projectId: number, description: string, owner: string, adminUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateProject', 'projectId', projectId)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('updateProject', 'description', description)
            // verify required parameter 'owner' is not null or undefined
            assertParamExists('updateProject', 'owner', owner)
            const localVarPath = `/admin/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (adminUrl !== undefined) {
                localVarQueryParameter['adminUrl'] = adminUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roleId 
         * @param {string} name 
         * @param {boolean} isPublic 
         * @param {Array<number>} privileges 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole: async (roleId: number, name: string, isPublic: boolean, privileges: Array<number>, description: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('updateRole', 'roleId', roleId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('updateRole', 'name', name)
            // verify required parameter 'isPublic' is not null or undefined
            assertParamExists('updateRole', 'isPublic', isPublic)
            // verify required parameter 'privileges' is not null or undefined
            assertParamExists('updateRole', 'privileges', privileges)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('updateRole', 'description', description)
            const localVarPath = `/admin/role/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (isPublic !== undefined) {
                localVarQueryParameter['isPublic'] = isPublic;
            }

            if (privileges) {
                localVarQueryParameter['privileges'] = privileges;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} secretId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSecret: async (secretId: number, key: string, value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'secretId' is not null or undefined
            assertParamExists('updateSecret', 'secretId', secretId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('updateSecret', 'key', key)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('updateSecret', 'value', value)
            const localVarPath = `/admin/secret/{secretId}`
                .replace(`{${"secretId"}}`, encodeURIComponent(String(secretId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} name 
         * @param {string} description 
         * @param {number} replicaCount 
         * @param {string} version 
         * @param {string} image 
         * @param {number} port 
         * @param {number} containerPort 
         * @param {boolean} redirectWWW 
         * @param {boolean} isWebFrontend 
         * @param {'NONE' | 'PROJECT' | 'ALL'} corsLevel 
         * @param {boolean} oauthComponent 
         * @param {Array<number>} [dependencies] 
         * @param {string} [host] 
         * @param {string} [basicAuthUser] 
         * @param {string} [basicAuthPassword] 
         * @param {string} [clientId] 
         * @param {string} [clientPassword] 
         * @param {string} [clientScope] 
         * @param {string} [clientRedirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateService: async (serviceId: number, name: string, description: string, replicaCount: number, version: string, image: string, port: number, containerPort: number, redirectWWW: boolean, isWebFrontend: boolean, corsLevel: 'NONE' | 'PROJECT' | 'ALL', oauthComponent: boolean, dependencies?: Array<number>, host?: string, basicAuthUser?: string, basicAuthPassword?: string, clientId?: string, clientPassword?: string, clientScope?: string, clientRedirectUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('updateService', 'serviceId', serviceId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('updateService', 'name', name)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('updateService', 'description', description)
            // verify required parameter 'replicaCount' is not null or undefined
            assertParamExists('updateService', 'replicaCount', replicaCount)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('updateService', 'version', version)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('updateService', 'image', image)
            // verify required parameter 'port' is not null or undefined
            assertParamExists('updateService', 'port', port)
            // verify required parameter 'containerPort' is not null or undefined
            assertParamExists('updateService', 'containerPort', containerPort)
            // verify required parameter 'redirectWWW' is not null or undefined
            assertParamExists('updateService', 'redirectWWW', redirectWWW)
            // verify required parameter 'isWebFrontend' is not null or undefined
            assertParamExists('updateService', 'isWebFrontend', isWebFrontend)
            // verify required parameter 'corsLevel' is not null or undefined
            assertParamExists('updateService', 'corsLevel', corsLevel)
            // verify required parameter 'oauthComponent' is not null or undefined
            assertParamExists('updateService', 'oauthComponent', oauthComponent)
            const localVarPath = `/admin/service/{serviceId}`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (replicaCount !== undefined) {
                localVarQueryParameter['replicaCount'] = replicaCount;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (image !== undefined) {
                localVarQueryParameter['image'] = image;
            }

            if (port !== undefined) {
                localVarQueryParameter['port'] = port;
            }

            if (containerPort !== undefined) {
                localVarQueryParameter['containerPort'] = containerPort;
            }

            if (dependencies) {
                localVarQueryParameter['dependencies'] = dependencies;
            }

            if (host !== undefined) {
                localVarQueryParameter['host'] = host;
            }

            if (basicAuthUser !== undefined) {
                localVarQueryParameter['basicAuthUser'] = basicAuthUser;
            }

            if (basicAuthPassword !== undefined) {
                localVarQueryParameter['basicAuthPassword'] = basicAuthPassword;
            }

            if (redirectWWW !== undefined) {
                localVarQueryParameter['redirectWWW'] = redirectWWW;
            }

            if (isWebFrontend !== undefined) {
                localVarQueryParameter['isWebFrontend'] = isWebFrontend;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (clientPassword !== undefined) {
                localVarQueryParameter['clientPassword'] = clientPassword;
            }

            if (clientScope !== undefined) {
                localVarQueryParameter['clientScope'] = clientScope;
            }

            if (clientRedirectUrl !== undefined) {
                localVarQueryParameter['clientRedirectUrl'] = clientRedirectUrl;
            }

            if (corsLevel !== undefined) {
                localVarQueryParameter['corsLevel'] = corsLevel;
            }

            if (oauthComponent !== undefined) {
                localVarQueryParameter['oauthComponent'] = oauthComponent;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} volumeId 
         * @param {string} name 
         * @param {string} size 
         * @param {string} mount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVolume: async (volumeId: number, name: string, size: string, mount: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'volumeId' is not null or undefined
            assertParamExists('updateVolume', 'volumeId', volumeId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('updateVolume', 'name', name)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('updateVolume', 'size', size)
            // verify required parameter 'mount' is not null or undefined
            assertParamExists('updateVolume', 'mount', mount)
            const localVarPath = `/admin/volume/{volumeId}`
                .replace(`{${"volumeId"}}`, encodeURIComponent(String(volumeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (mount !== undefined) {
                localVarQueryParameter['mount'] = mount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminControllerApi - functional programming interface
 * @export
 */
export const AdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmationToken(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmationToken(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {string} [value] 
         * @param {number} [secretId] 
         * @param {number} [parameterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEnvironmentVariable(serviceId: number, key: string, value?: string, secretId?: number, parameterId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentVariableResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEnvironmentVariable(serviceId, key, value, secretId, parameterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {Array<number>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIdentity(email: string, password: string, roles: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIdentity(email, password, roles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createParameter(serviceId: number, key: string, value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParameterResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createParameter(serviceId, key, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {Array<string>} names 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPrivileges(projectId: number, names: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrivilegeResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPrivileges(projectId, names, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} description 
         * @param {string} owner 
         * @param {string} privilege 
         * @param {string} role 
         * @param {string} [adminUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(name: string, description: string, owner: string, privilege: string, role: string, adminUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(name, description, owner, privilege, role, adminUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} name 
         * @param {boolean} isPublic 
         * @param {Array<number>} privileges 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRole(projectId: number, name: string, isPublic: boolean, privileges: Array<number>, description: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRole(projectId, name, isPublic, privileges, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSecret(serviceId: number, key: string, value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSecret(serviceId, key, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} name 
         * @param {string} description 
         * @param {number} replicaCount 
         * @param {string} version 
         * @param {string} image 
         * @param {number} port 
         * @param {number} containerPort 
         * @param {boolean} redirectWWW 
         * @param {boolean} isWebFrontend 
         * @param {'NONE' | 'PROJECT' | 'ALL'} corsLevel 
         * @param {boolean} oauthComponent 
         * @param {Array<number>} [dependencies] 
         * @param {string} [host] 
         * @param {string} [basicAuthUser] 
         * @param {string} [basicAuthPassword] 
         * @param {string} [clientId] 
         * @param {string} [clientPassword] 
         * @param {string} [clientScope] 
         * @param {string} [clientRedirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createService(projectId: number, name: string, description: string, replicaCount: number, version: string, image: string, port: number, containerPort: number, redirectWWW: boolean, isWebFrontend: boolean, corsLevel: 'NONE' | 'PROJECT' | 'ALL', oauthComponent: boolean, dependencies?: Array<number>, host?: string, basicAuthUser?: string, basicAuthPassword?: string, clientId?: string, clientPassword?: string, clientScope?: string, clientRedirectUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createService(projectId, name, description, replicaCount, version, image, port, containerPort, redirectWWW, isWebFrontend, corsLevel, oauthComponent, dependencies, host, basicAuthUser, basicAuthPassword, clientId, clientPassword, clientScope, clientRedirectUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} name 
         * @param {string} size 
         * @param {string} mount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVolume(serviceId: number, name: string, size: string, mount: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VolumeResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVolume(serviceId, name, size, mount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} envId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEnvironmentVariable(envId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEnvironmentVariable(envId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} parameterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteParameter(parameterId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteParameter(parameterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} secretId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSecret(secretId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSecret(secretId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteService(serviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteService(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} volumeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVolume(volumeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVolume(volumeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurationResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdentitiesPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageIdentityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdentitiesPaginated(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdentityByEmail(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdentityByEmail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageProjectResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsPaginated(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} gitUserName 
         * @param {string} gitPassword 
         * @param {string} helmRepository 
         * @param {string} terraformRepository 
         * @param {string} doToken 
         * @param {string} springCloudConfigRepository 
         * @param {string} launchpadApiGateway 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putConfiguration(gitUserName: string, gitPassword: string, helmRepository: string, terraformRepository: string, doToken: string, springCloudConfigRepository: string, launchpadApiGateway: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurationResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putConfiguration(gitUserName, gitPassword, helmRepository, terraformRepository, doToken, springCloudConfigRepository, launchpadApiGateway, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} major 
         * @param {number} minor 
         * @param {number} patch 
         * @param {string} [cause] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVersion(projectId: number, major: number, minor: number, patch: number, cause?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVersion(projectId, major, minor, patch, cause, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} identityId 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setIdentityRole(identityId: number, roleId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setIdentityRole(identityId, roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} privilegeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setRolePrivilege(roleId: number, privilegeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setRolePrivilege(roleId, privilegeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} envId 
         * @param {string} key 
         * @param {string} [value] 
         * @param {number} [secretId] 
         * @param {number} [parameterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEnvironmentVariable(envId: number, key: string, value?: string, secretId?: number, parameterId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentVariableResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEnvironmentVariable(envId, key, value, secretId, parameterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} identityId 
         * @param {Array<number>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIdentity(identityId: number, roles: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIdentity(identityId, roles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} parameterId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateParameter(parameterId: number, key: string, value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParameterResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateParameter(parameterId, key, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} description 
         * @param {string} owner 
         * @param {string} [adminUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(projectId: number, description: string, owner: string, adminUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(projectId, description, owner, adminUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roleId 
         * @param {string} name 
         * @param {boolean} isPublic 
         * @param {Array<number>} privileges 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRole(roleId: number, name: string, isPublic: boolean, privileges: Array<number>, description: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRole(roleId, name, isPublic, privileges, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} secretId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSecret(secretId: number, key: string, value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSecret(secretId, key, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} name 
         * @param {string} description 
         * @param {number} replicaCount 
         * @param {string} version 
         * @param {string} image 
         * @param {number} port 
         * @param {number} containerPort 
         * @param {boolean} redirectWWW 
         * @param {boolean} isWebFrontend 
         * @param {'NONE' | 'PROJECT' | 'ALL'} corsLevel 
         * @param {boolean} oauthComponent 
         * @param {Array<number>} [dependencies] 
         * @param {string} [host] 
         * @param {string} [basicAuthUser] 
         * @param {string} [basicAuthPassword] 
         * @param {string} [clientId] 
         * @param {string} [clientPassword] 
         * @param {string} [clientScope] 
         * @param {string} [clientRedirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateService(serviceId: number, name: string, description: string, replicaCount: number, version: string, image: string, port: number, containerPort: number, redirectWWW: boolean, isWebFrontend: boolean, corsLevel: 'NONE' | 'PROJECT' | 'ALL', oauthComponent: boolean, dependencies?: Array<number>, host?: string, basicAuthUser?: string, basicAuthPassword?: string, clientId?: string, clientPassword?: string, clientScope?: string, clientRedirectUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateService(serviceId, name, description, replicaCount, version, image, port, containerPort, redirectWWW, isWebFrontend, corsLevel, oauthComponent, dependencies, host, basicAuthUser, basicAuthPassword, clientId, clientPassword, clientScope, clientRedirectUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} volumeId 
         * @param {string} name 
         * @param {string} size 
         * @param {string} mount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVolume(volumeId: number, name: string, size: string, mount: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VolumeResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVolume(volumeId, name, size, mount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminControllerApi - factory interface
 * @export
 */
export const AdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationToken(email: string, options?: any): AxiosPromise<string> {
            return localVarFp.confirmationToken(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {string} [value] 
         * @param {number} [secretId] 
         * @param {number} [parameterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnvironmentVariable(serviceId: number, key: string, value?: string, secretId?: number, parameterId?: number, options?: any): AxiosPromise<EnvironmentVariableResource> {
            return localVarFp.createEnvironmentVariable(serviceId, key, value, secretId, parameterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {Array<number>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIdentity(email: string, password: string, roles: Array<number>, options?: any): AxiosPromise<IdentityResource> {
            return localVarFp.createIdentity(email, password, roles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParameter(serviceId: number, key: string, value: string, options?: any): AxiosPromise<ParameterResource> {
            return localVarFp.createParameter(serviceId, key, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {Array<string>} names 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrivileges(projectId: number, names: Array<string>, options?: any): AxiosPromise<Array<PrivilegeResource>> {
            return localVarFp.createPrivileges(projectId, names, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} description 
         * @param {string} owner 
         * @param {string} privilege 
         * @param {string} role 
         * @param {string} [adminUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(name: string, description: string, owner: string, privilege: string, role: string, adminUrl?: string, options?: any): AxiosPromise<ProjectResource> {
            return localVarFp.createProject(name, description, owner, privilege, role, adminUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} name 
         * @param {boolean} isPublic 
         * @param {Array<number>} privileges 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole(projectId: number, name: string, isPublic: boolean, privileges: Array<number>, description: string, options?: any): AxiosPromise<RoleResource> {
            return localVarFp.createRole(projectId, name, isPublic, privileges, description, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSecret(serviceId: number, key: string, value: string, options?: any): AxiosPromise<SecretResource> {
            return localVarFp.createSecret(serviceId, key, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} name 
         * @param {string} description 
         * @param {number} replicaCount 
         * @param {string} version 
         * @param {string} image 
         * @param {number} port 
         * @param {number} containerPort 
         * @param {boolean} redirectWWW 
         * @param {boolean} isWebFrontend 
         * @param {'NONE' | 'PROJECT' | 'ALL'} corsLevel 
         * @param {boolean} oauthComponent 
         * @param {Array<number>} [dependencies] 
         * @param {string} [host] 
         * @param {string} [basicAuthUser] 
         * @param {string} [basicAuthPassword] 
         * @param {string} [clientId] 
         * @param {string} [clientPassword] 
         * @param {string} [clientScope] 
         * @param {string} [clientRedirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createService(projectId: number, name: string, description: string, replicaCount: number, version: string, image: string, port: number, containerPort: number, redirectWWW: boolean, isWebFrontend: boolean, corsLevel: 'NONE' | 'PROJECT' | 'ALL', oauthComponent: boolean, dependencies?: Array<number>, host?: string, basicAuthUser?: string, basicAuthPassword?: string, clientId?: string, clientPassword?: string, clientScope?: string, clientRedirectUrl?: string, options?: any): AxiosPromise<ServiceResource> {
            return localVarFp.createService(projectId, name, description, replicaCount, version, image, port, containerPort, redirectWWW, isWebFrontend, corsLevel, oauthComponent, dependencies, host, basicAuthUser, basicAuthPassword, clientId, clientPassword, clientScope, clientRedirectUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} name 
         * @param {string} size 
         * @param {string} mount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVolume(serviceId: number, name: string, size: string, mount: string, options?: any): AxiosPromise<VolumeResource> {
            return localVarFp.createVolume(serviceId, name, size, mount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} envId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironmentVariable(envId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteEnvironmentVariable(envId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} parameterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParameter(parameterId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteParameter(parameterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} secretId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSecret(secretId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteSecret(secretId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteService(serviceId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteService(serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} volumeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVolume(volumeId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteVolume(volumeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfiguration(options?: any): AxiosPromise<ConfigurationResource> {
            return localVarFp.getConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdentitiesPaginated(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageIdentityResource> {
            return localVarFp.getIdentitiesPaginated(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdentityByEmail(email: string, options?: any): AxiosPromise<IdentityResource> {
            return localVarFp.getIdentityByEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsPaginated(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageProjectResource> {
            return localVarFp.getProjectsPaginated(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} gitUserName 
         * @param {string} gitPassword 
         * @param {string} helmRepository 
         * @param {string} terraformRepository 
         * @param {string} doToken 
         * @param {string} springCloudConfigRepository 
         * @param {string} launchpadApiGateway 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putConfiguration(gitUserName: string, gitPassword: string, helmRepository: string, terraformRepository: string, doToken: string, springCloudConfigRepository: string, launchpadApiGateway: string, options?: any): AxiosPromise<ConfigurationResource> {
            return localVarFp.putConfiguration(gitUserName, gitPassword, helmRepository, terraformRepository, doToken, springCloudConfigRepository, launchpadApiGateway, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} major 
         * @param {number} minor 
         * @param {number} patch 
         * @param {string} [cause] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVersion(projectId: number, major: number, minor: number, patch: number, cause?: string, options?: any): AxiosPromise<DeploymentResource> {
            return localVarFp.putVersion(projectId, major, minor, patch, cause, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} identityId 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setIdentityRole(identityId: number, roleId: number, options?: any): AxiosPromise<IdentityResource> {
            return localVarFp.setIdentityRole(identityId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} privilegeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRolePrivilege(roleId: number, privilegeId: number, options?: any): AxiosPromise<RoleResource> {
            return localVarFp.setRolePrivilege(roleId, privilegeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} envId 
         * @param {string} key 
         * @param {string} [value] 
         * @param {number} [secretId] 
         * @param {number} [parameterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnvironmentVariable(envId: number, key: string, value?: string, secretId?: number, parameterId?: number, options?: any): AxiosPromise<EnvironmentVariableResource> {
            return localVarFp.updateEnvironmentVariable(envId, key, value, secretId, parameterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} identityId 
         * @param {Array<number>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdentity(identityId: number, roles: Array<number>, options?: any): AxiosPromise<IdentityResource> {
            return localVarFp.updateIdentity(identityId, roles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} parameterId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParameter(parameterId: number, key: string, value: string, options?: any): AxiosPromise<ParameterResource> {
            return localVarFp.updateParameter(parameterId, key, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} description 
         * @param {string} owner 
         * @param {string} [adminUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId: number, description: string, owner: string, adminUrl?: string, options?: any): AxiosPromise<ProjectResource> {
            return localVarFp.updateProject(projectId, description, owner, adminUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} roleId 
         * @param {string} name 
         * @param {boolean} isPublic 
         * @param {Array<number>} privileges 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole(roleId: number, name: string, isPublic: boolean, privileges: Array<number>, description: string, options?: any): AxiosPromise<RoleResource> {
            return localVarFp.updateRole(roleId, name, isPublic, privileges, description, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} secretId 
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSecret(secretId: number, key: string, value: string, options?: any): AxiosPromise<SecretResource> {
            return localVarFp.updateSecret(secretId, key, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {string} name 
         * @param {string} description 
         * @param {number} replicaCount 
         * @param {string} version 
         * @param {string} image 
         * @param {number} port 
         * @param {number} containerPort 
         * @param {boolean} redirectWWW 
         * @param {boolean} isWebFrontend 
         * @param {'NONE' | 'PROJECT' | 'ALL'} corsLevel 
         * @param {boolean} oauthComponent 
         * @param {Array<number>} [dependencies] 
         * @param {string} [host] 
         * @param {string} [basicAuthUser] 
         * @param {string} [basicAuthPassword] 
         * @param {string} [clientId] 
         * @param {string} [clientPassword] 
         * @param {string} [clientScope] 
         * @param {string} [clientRedirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateService(serviceId: number, name: string, description: string, replicaCount: number, version: string, image: string, port: number, containerPort: number, redirectWWW: boolean, isWebFrontend: boolean, corsLevel: 'NONE' | 'PROJECT' | 'ALL', oauthComponent: boolean, dependencies?: Array<number>, host?: string, basicAuthUser?: string, basicAuthPassword?: string, clientId?: string, clientPassword?: string, clientScope?: string, clientRedirectUrl?: string, options?: any): AxiosPromise<ServiceResource> {
            return localVarFp.updateService(serviceId, name, description, replicaCount, version, image, port, containerPort, redirectWWW, isWebFrontend, corsLevel, oauthComponent, dependencies, host, basicAuthUser, basicAuthPassword, clientId, clientPassword, clientScope, clientRedirectUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} volumeId 
         * @param {string} name 
         * @param {string} size 
         * @param {string} mount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVolume(volumeId: number, name: string, size: string, mount: string, options?: any): AxiosPromise<VolumeResource> {
            return localVarFp.updateVolume(volumeId, name, size, mount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminControllerApi - object-oriented interface
 * @export
 * @class AdminControllerApi
 * @extends {BaseAPI}
 */
export class AdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public confirmationToken(email: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).confirmationToken(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {string} key 
     * @param {string} [value] 
     * @param {number} [secretId] 
     * @param {number} [parameterId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createEnvironmentVariable(serviceId: number, key: string, value?: string, secretId?: number, parameterId?: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createEnvironmentVariable(serviceId, key, value, secretId, parameterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {string} password 
     * @param {Array<number>} roles 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createIdentity(email: string, password: string, roles: Array<number>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createIdentity(email, password, roles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {string} key 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createParameter(serviceId: number, key: string, value: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createParameter(serviceId, key, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {Array<string>} names 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createPrivileges(projectId: number, names: Array<string>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createPrivileges(projectId, names, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} description 
     * @param {string} owner 
     * @param {string} privilege 
     * @param {string} role 
     * @param {string} [adminUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createProject(name: string, description: string, owner: string, privilege: string, role: string, adminUrl?: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createProject(name, description, owner, privilege, role, adminUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} name 
     * @param {boolean} isPublic 
     * @param {Array<number>} privileges 
     * @param {string} description 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createRole(projectId: number, name: string, isPublic: boolean, privileges: Array<number>, description: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createRole(projectId, name, isPublic, privileges, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {string} key 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createSecret(serviceId: number, key: string, value: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createSecret(serviceId, key, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} name 
     * @param {string} description 
     * @param {number} replicaCount 
     * @param {string} version 
     * @param {string} image 
     * @param {number} port 
     * @param {number} containerPort 
     * @param {boolean} redirectWWW 
     * @param {boolean} isWebFrontend 
     * @param {'NONE' | 'PROJECT' | 'ALL'} corsLevel 
     * @param {boolean} oauthComponent 
     * @param {Array<number>} [dependencies] 
     * @param {string} [host] 
     * @param {string} [basicAuthUser] 
     * @param {string} [basicAuthPassword] 
     * @param {string} [clientId] 
     * @param {string} [clientPassword] 
     * @param {string} [clientScope] 
     * @param {string} [clientRedirectUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createService(projectId: number, name: string, description: string, replicaCount: number, version: string, image: string, port: number, containerPort: number, redirectWWW: boolean, isWebFrontend: boolean, corsLevel: 'NONE' | 'PROJECT' | 'ALL', oauthComponent: boolean, dependencies?: Array<number>, host?: string, basicAuthUser?: string, basicAuthPassword?: string, clientId?: string, clientPassword?: string, clientScope?: string, clientRedirectUrl?: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createService(projectId, name, description, replicaCount, version, image, port, containerPort, redirectWWW, isWebFrontend, corsLevel, oauthComponent, dependencies, host, basicAuthUser, basicAuthPassword, clientId, clientPassword, clientScope, clientRedirectUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {string} name 
     * @param {string} size 
     * @param {string} mount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createVolume(serviceId: number, name: string, size: string, mount: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createVolume(serviceId, name, size, mount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} envId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteEnvironmentVariable(envId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteEnvironmentVariable(envId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} parameterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteParameter(parameterId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteParameter(parameterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} secretId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteSecret(secretId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteSecret(secretId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteService(serviceId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteService(serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} volumeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteVolume(volumeId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteVolume(volumeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getConfiguration(options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getIdentitiesPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getIdentitiesPaginated(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getIdentityByEmail(email: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getIdentityByEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getProjectsPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getProjectsPaginated(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} gitUserName 
     * @param {string} gitPassword 
     * @param {string} helmRepository 
     * @param {string} terraformRepository 
     * @param {string} doToken 
     * @param {string} springCloudConfigRepository 
     * @param {string} launchpadApiGateway 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putConfiguration(gitUserName: string, gitPassword: string, helmRepository: string, terraformRepository: string, doToken: string, springCloudConfigRepository: string, launchpadApiGateway: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putConfiguration(gitUserName, gitPassword, helmRepository, terraformRepository, doToken, springCloudConfigRepository, launchpadApiGateway, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} major 
     * @param {number} minor 
     * @param {number} patch 
     * @param {string} [cause] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putVersion(projectId: number, major: number, minor: number, patch: number, cause?: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putVersion(projectId, major, minor, patch, cause, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} identityId 
     * @param {number} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public setIdentityRole(identityId: number, roleId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).setIdentityRole(identityId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} roleId 
     * @param {number} privilegeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public setRolePrivilege(roleId: number, privilegeId: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).setRolePrivilege(roleId, privilegeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} envId 
     * @param {string} key 
     * @param {string} [value] 
     * @param {number} [secretId] 
     * @param {number} [parameterId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateEnvironmentVariable(envId: number, key: string, value?: string, secretId?: number, parameterId?: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateEnvironmentVariable(envId, key, value, secretId, parameterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} identityId 
     * @param {Array<number>} roles 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateIdentity(identityId: number, roles: Array<number>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateIdentity(identityId, roles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} parameterId 
     * @param {string} key 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateParameter(parameterId: number, key: string, value: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateParameter(parameterId, key, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} description 
     * @param {string} owner 
     * @param {string} [adminUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateProject(projectId: number, description: string, owner: string, adminUrl?: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateProject(projectId, description, owner, adminUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} roleId 
     * @param {string} name 
     * @param {boolean} isPublic 
     * @param {Array<number>} privileges 
     * @param {string} description 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateRole(roleId: number, name: string, isPublic: boolean, privileges: Array<number>, description: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateRole(roleId, name, isPublic, privileges, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} secretId 
     * @param {string} key 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateSecret(secretId: number, key: string, value: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateSecret(secretId, key, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {string} name 
     * @param {string} description 
     * @param {number} replicaCount 
     * @param {string} version 
     * @param {string} image 
     * @param {number} port 
     * @param {number} containerPort 
     * @param {boolean} redirectWWW 
     * @param {boolean} isWebFrontend 
     * @param {'NONE' | 'PROJECT' | 'ALL'} corsLevel 
     * @param {boolean} oauthComponent 
     * @param {Array<number>} [dependencies] 
     * @param {string} [host] 
     * @param {string} [basicAuthUser] 
     * @param {string} [basicAuthPassword] 
     * @param {string} [clientId] 
     * @param {string} [clientPassword] 
     * @param {string} [clientScope] 
     * @param {string} [clientRedirectUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateService(serviceId: number, name: string, description: string, replicaCount: number, version: string, image: string, port: number, containerPort: number, redirectWWW: boolean, isWebFrontend: boolean, corsLevel: 'NONE' | 'PROJECT' | 'ALL', oauthComponent: boolean, dependencies?: Array<number>, host?: string, basicAuthUser?: string, basicAuthPassword?: string, clientId?: string, clientPassword?: string, clientScope?: string, clientRedirectUrl?: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateService(serviceId, name, description, replicaCount, version, image, port, containerPort, redirectWWW, isWebFrontend, corsLevel, oauthComponent, dependencies, host, basicAuthUser, basicAuthPassword, clientId, clientPassword, clientScope, clientRedirectUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} volumeId 
     * @param {string} name 
     * @param {string} size 
     * @param {string} mount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateVolume(volumeId: number, name: string, size: string, mount: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateVolume(volumeId, name, size, mount, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicControllerApi - axios parameter creator
 * @export
 */
export const PublicControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticated: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/authenticated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} password 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (password: string, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'password' is not null or undefined
            assertParamExists('changePassword', 'password', password)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('changePassword', 'token', token)
            const localVarPath = `/public/changePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} base64loginUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUserAccount: async (token: string, base64loginUrl: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('confirmUserAccount', 'token', token)
            // verify required parameter 'base64loginUrl' is not null or undefined
            assertParamExists('confirmUserAccount', 'base64loginUrl', base64loginUrl)
            const localVarPath = `/public/confirmAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (base64loginUrl !== undefined) {
                localVarQueryParameter['base64loginUrl'] = base64loginUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicProject: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getPublicProject', 'name', name)
            const localVarPath = `/public/project/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/foo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/foo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/foo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo3: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/foo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo4: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/foo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo5: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/foo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo6: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/foo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {Array<number>} roles 
         * @param {string} redirectUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (email: string, password: string, roles: Array<number>, redirectUrl: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('register', 'email', email)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('register', 'password', password)
            // verify required parameter 'roles' is not null or undefined
            assertParamExists('register', 'roles', roles)
            // verify required parameter 'redirectUrl' is not null or undefined
            assertParamExists('register', 'redirectUrl', redirectUrl)
            const localVarPath = `/public/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }

            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }

            if (redirectUrl !== undefined) {
                localVarQueryParameter['redirectUrl'] = redirectUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('resetPassword', 'email', email)
            const localVarPath = `/public/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicControllerApi - functional programming interface
 * @export
 */
export const PublicControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticated(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticated(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} password 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(password: string, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(password, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {string} base64loginUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmUserAccount(token: string, base64loginUrl: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmUserAccount(token, base64loginUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicProject(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicProject(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFoo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFoo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFoo1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFoo1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFoo2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFoo2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFoo3(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFoo3(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFoo4(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFoo4(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFoo5(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFoo5(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFoo6(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFoo6(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {Array<number>} roles 
         * @param {string} redirectUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(email: string, password: string, roles: Array<number>, redirectUrl: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(email, password, roles, redirectUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicControllerApi - factory interface
 * @export
 */
export const PublicControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticated(options?: any): AxiosPromise<boolean> {
            return localVarFp.authenticated(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} password 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(password: string, token: string, options?: any): AxiosPromise<string> {
            return localVarFp.changePassword(password, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {string} base64loginUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUserAccount(token: string, base64loginUrl: string, options?: any): AxiosPromise<void> {
            return localVarFp.confirmUserAccount(token, base64loginUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicProject(name: string, options?: any): AxiosPromise<ProjectResource> {
            return localVarFp.getPublicProject(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo(options?: any): AxiosPromise<void> {
            return localVarFp.handleFoo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo1(options?: any): AxiosPromise<void> {
            return localVarFp.handleFoo1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo2(options?: any): AxiosPromise<void> {
            return localVarFp.handleFoo2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo3(options?: any): AxiosPromise<void> {
            return localVarFp.handleFoo3(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo4(options?: any): AxiosPromise<void> {
            return localVarFp.handleFoo4(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo5(options?: any): AxiosPromise<void> {
            return localVarFp.handleFoo5(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFoo6(options?: any): AxiosPromise<void> {
            return localVarFp.handleFoo6(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {Array<number>} roles 
         * @param {string} redirectUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(email: string, password: string, roles: Array<number>, redirectUrl: string, options?: any): AxiosPromise<IdentityResource> {
            return localVarFp.register(email, password, roles, redirectUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(email: string, options?: any): AxiosPromise<string> {
            return localVarFp.resetPassword(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicControllerApi - object-oriented interface
 * @export
 * @class PublicControllerApi
 * @extends {BaseAPI}
 */
export class PublicControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public authenticated(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).authenticated(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} password 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public changePassword(password: string, token: string, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).changePassword(password, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {string} base64loginUrl 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public confirmUserAccount(token: string, base64loginUrl: string, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).confirmUserAccount(token, base64loginUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getPublicProject(name: string, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getPublicProject(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public handleFoo(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).handleFoo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public handleFoo1(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).handleFoo1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public handleFoo2(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).handleFoo2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public handleFoo3(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).handleFoo3(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public handleFoo4(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).handleFoo4(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public handleFoo5(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).handleFoo5(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public handleFoo6(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).handleFoo6(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {string} password 
     * @param {Array<number>} roles 
     * @param {string} redirectUrl 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public register(email: string, password: string, roles: Array<number>, redirectUrl: string, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).register(email, password, roles, redirectUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public resetPassword(email: string, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).resetPassword(email, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/me/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication security_auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "security_auth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async user(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.user(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user(options?: any): AxiosPromise<IdentityResource> {
            return localVarFp.user(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getRoles(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public user(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).user(options).then((request) => request(this.axios, this.basePath));
    }
}


