import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ErrorHandler from '../ErrorHandler/ErrorHandler';
import Root from '../Root/Root';
import { useIntl, FormattedMessage } from 'react-intl';
import { Environment } from '../Environment'
import { Route, Routes } from 'react-router-dom';
import theme from '../theme'
import { useNavigate } from 'react-router-dom'
import { ListItemIcon } from '@mui/material';
import Button from '@mui/material/Button'
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MessageIcon from '@mui/icons-material/Message';
import HelpIcon from '@mui/icons-material/Help';
import AsyncLock from 'async-lock';

var ceateLawyerLock = new AsyncLock();
var companyLawyerLock = new AsyncLock();

import { CompanyEntityResource, LawyerEntityResource, MeResource, MeResourceTypeEnum, LawyerEntityResourceStateEnum, CompanyEntityResourceStateEnum } from '../generated/counselme-api'

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false)
  const [parentUrl, setParentUrl] = React.useState<string | undefined>(undefined)
  const [me, setMe] = React.useState<MeResource | undefined>(undefined)
  const [isDrawerVisible, setIsDrawerVisible] = React.useState<boolean>(false)
  const [lawyer, setLawyer] = React.useState<LawyerEntityResource | undefined>(undefined)
  const [company, setCompany] = React.useState<CompanyEntityResource | undefined>(undefined)
  const [newMessages, setNewMessages] = React.useState<number>(0)
  const [seconds, setSeconds] = React.useState(0);
  const [environment, setEnvironment] = React.useState<Environment | undefined>(undefined);
  const messagingIconRef = React.useRef<HTMLDivElement>(null);

  const intl = useIntl()
  const navigate = useNavigate()

  React.useEffect(() => {
    Environment.getInstance().then((environment) => {
      setEnvironment(environment)
    })
  }, []);

  React.useEffect(() => {
    if(environment) {
      environment.launchpadPublicControllerApi.authenticated({ withCredentials: true }).then((result) => {
        setIsAuthenticated(result.data)
      })

      setParentUrl(environment.parentUrl)
    }
  }, [environment]);

  React.useEffect(() => {
    if(environment) {
      if (isAuthenticated) {
        environment.counselMeAuthenticatedApi.me2({ withCredentials: true }).then((result) => {
          setMe(result.data)
        })
      }
    }
  }, [isAuthenticated, environment]);


  React.useEffect(() => {
    if(isAuthenticated && environment) {
      if (seconds === 0) {
        environment.counselMeAuthenticatedApi.hasNewMessages({ withCredentials: true }).then((result) => {
          setNewMessages(result.data)
        });

        let interval = setInterval(() => {
          setSeconds(seconds + 1 === 10 ? 0 : seconds + 1);
        }, 1000);

        return () => clearInterval(interval);
      }
    }

    return () => { }
  }, [isAuthenticated, seconds, environment])


  React.useEffect(() => {
    if(environment && me) {
      if (me.type === MeResourceTypeEnum.CompanyNotYetCreated) {
        companyLawyerLock.acquire("CreateCompany", function () {
          if (me.type === MeResourceTypeEnum.CompanyNotYetCreated) {
            return environment.counselMeCompanyControllerApi.createCompany({ withCredentials: true }).then((_) => {
              environment.counselMeAuthenticatedApi.me2({ withCredentials: true }).then((result) => {
                setMe(result.data)
              })
            })
          }
        });

      } else if (me?.type === MeResourceTypeEnum.LawyerNotYetCreated) {
        ceateLawyerLock.acquire("CreateLawyer", function () {
          if (me.type === MeResourceTypeEnum.LawyerNotYetCreated) {
            return environment.counselMeLawyerControllerApi.createLawyer({ withCredentials: true }).then((_) => {
              environment.counselMeAuthenticatedApi.me2({ withCredentials: true }).then((result) => {
                setMe(result.data)
              })
            })
          }
        });
      } else if (me.type === MeResourceTypeEnum.Lawyer) {
          environment.counselMeLawyerControllerApi.me({ withCredentials: true }).then((result) => {
            setLawyer(result.data)
          })
      } else if (me.type === MeResourceTypeEnum.Company) {
          environment.counselMeCompanyControllerApi.me1({ withCredentials: true }).then((result) => {
            setCompany(result.data)
          })
      }
    }
  }, [me, environment]);

  React.useEffect(() => {

    if (lawyer) {
      switch (lawyer.state) {
        case LawyerEntityResourceStateEnum.Activated: {
          setIsDrawerVisible(true)
          navigate("/lawyerdashboard");
          return;
        }
        case LawyerEntityResourceStateEnum.Created: {
          navigate("/lawyeronboarding");
          return;
        }
        case LawyerEntityResourceStateEnum.Deactivated: {
          navigate("/userdeactivated");
          return;
        }
        case LawyerEntityResourceStateEnum.Rejected: {
          navigate("/lawyerrejected");
          return;
        }
        case LawyerEntityResourceStateEnum.WaitingForApproval: {
          navigate("/lawyerwaitingapproval");
          return;
        }
        default: {
          console.log("unhandled case " + lawyer.state)
          return;
        }
      }
    }

    if (company) {
      switch (company.state) {
        case CompanyEntityResourceStateEnum.Activated: {
          setIsDrawerVisible(true)
          navigate("/companydashboard");
          break;
        }
        case CompanyEntityResourceStateEnum.Created: {
          navigate("/companyonboarding");
          break;
        }
        case CompanyEntityResourceStateEnum.Deactivated: {
          navigate("/userdeactivated");
          break;
        }
        case CompanyEntityResourceStateEnum.Rejected: {
          navigate("/companyrejected");
          break;
        }
        case CompanyEntityResourceStateEnum.WaitingForApproval: {
          navigate("/companywaitingapproval");
          break;
        }
        default: {
          console.log("unhandled case " + company.state)
          break;
        }
      }
    }
  }, [lawyer, company]);

  type Action = {
    name: string;
    action: () => void;
  };

  const resizeObserver = new ResizeObserver(
    (entries: ResizeObserverEntry[], observer: ResizeObserver) => {
      for (let entry of entries) {
        // Do something with an entry (see in next section)
        console.log(entry)
      }
    });

  const links = [
    {
      name: intl.formatMessage({ id: 'link.user.deactivated' }),
      icon: null,
      component: React.lazy(() => import('../UserDeactivated/UserDeactivated')),
      path: '/userdeactivated',
      visible: (type: MeResourceTypeEnum | undefined) => {
        return false
      },
      actions: undefined as (Action[] | undefined)
    },
    {
      name: intl.formatMessage({ id: 'link.company.rejected' }),
      icon: null,
      component: React.lazy(() => import('../CompanyRejected/CompanyRejected')),
      path: '/companyrejected',
      visible: (type: MeResourceTypeEnum | undefined) => {
        return false
      },
      actions: undefined as (Action[] | undefined)
    },
    {
      name: intl.formatMessage({ id: 'link.company.waiting.approval' }),
      icon: null,
      component: React.lazy(() => import('../CompanyWaitingApproval/CompanyWaitingApproval')),
      path: '/companywaitingapproval',
      visible: (type: MeResourceTypeEnum | undefined) => {
        return false
      },
      actions: undefined as (Action[] | undefined)
    },
    {
      name: intl.formatMessage({ id: 'link.lawyer.rejected' }),
      icon: null,
      component: React.lazy(() => import('../LawyerRejected/LawyerRejected')),
      path: '/lawyerrejected',
      visible: (type: MeResourceTypeEnum | undefined) => {
        return false
      },
      actions: undefined as (Action[] | undefined)
    },
    {
      name: intl.formatMessage({ id: 'link.lawyer.waiting.approaval' }),
      icon: null,
      component: React.lazy(() => import('../LawyerWaitingApproval/LawyerWaitingApproval')),
      path: '/lawyerwaitingapproval',
      visible: (type: MeResourceTypeEnum | undefined) => {
        return false
      },
      actions: undefined as (Action[] | undefined)
    },
    {
      name: intl.formatMessage({ id: 'link.lawyer.onboarding' }),
      icon: null,
      component: React.lazy(() => import('../LawyerOnboarding/LawyerOnboarding')),
      path: '/lawyeronboarding',
      visible: (type: MeResourceTypeEnum | undefined) => {
        return false
      },
      actions: []
    },
    {
      name: intl.formatMessage({ id: 'link.company.onboarding' }),
      icon: null,
      component: React.lazy(() => import('../CompanyOnboarding/CompanyOnboarding')),
      path: '/companyonboarding',
      visible: (type: MeResourceTypeEnum | undefined) => {
        return false
      },
      actions: undefined as (Action[] | undefined)
    },
    {
      name: intl.formatMessage({ id: 'link.lawyer.dashboard' }),
      icon: <DashboardIcon />,
      component: React.lazy(() => import('../LawyerDashboard/LawyerDashboard')),
      path: '/lawyerdashboard',
      visible: (type: MeResourceTypeEnum | undefined) => {
        return type === MeResourceTypeEnum.Lawyer
      },
      actions: undefined as (Action[] | undefined)
    },
    {
      name: intl.formatMessage({ id: 'link.company.dashboard' }),
      icon: <DashboardIcon />,
      component: React.lazy(() => import('../CompanyDashboard/CompanyDashboard')),
      path: '/companydashboard',
      visible: (type: MeResourceTypeEnum | undefined) => {
        return type === MeResourceTypeEnum.Company
      },
      actions: undefined as (Action[] | undefined)
    },
    {
      name: intl.formatMessage({ id: 'link.lawyer.profile' }),
      icon: <ManageAccountsIcon />,
      component: React.lazy(() => import('../Profile/LawyerProfile')),
      path: '/lawyerprofile',
      visible: (type: MeResourceTypeEnum | undefined) => {
        return type === MeResourceTypeEnum.Lawyer
      },
      actions: undefined as (Action[] | undefined)
    },
    {
      name: intl.formatMessage({ id: 'link.company.profile' }),
      icon: <ManageAccountsIcon />,
      component: React.lazy(() => import('../Profile/CompanyProfile')),
      path: '/companyprofile',
      visible: (type: MeResourceTypeEnum | undefined) => {
        return type === MeResourceTypeEnum.Company
      },
      actions: undefined as (Action[] | undefined)
    },
    {
      name: intl.formatMessage({ id: 'link.messaging' }),
      icon: <div ref={messagingIconRef}>
        <MessageIcon/>
      </div>,
      component: React.lazy(() => import('../Messaging/Messaging')),
      path: '/messaging',
      visible: (type: MeResourceTypeEnum | undefined) => {
        return type === MeResourceTypeEnum.Company || type === MeResourceTypeEnum.Lawyer
      },
      actions: undefined as (Action[] | undefined)
    },

    {
      name: intl.formatMessage({ id: 'link.support' }),
      icon: <div ref={messagingIconRef}>
        <HelpIcon />
      </div>,
      component: React.lazy(() => import('../Support/Support')),
      path: '/support',
      visible: (type: MeResourceTypeEnum | undefined) => {
        return TrustedHTML
      },
      actions: undefined as (Action[] | undefined)
    },
  ];

  const routes = links.filter(link => link.visible(me?.type));

  const list = () => (
    <Box
      role="presentation">
      <List>
        {routes.map(link =>
          <ListItem key={link.name} disablePadding>
            <ListItemButton onClick={() => navigate(link.path)}>
              <ListItemIcon>
                {link.icon}
              </ListItemIcon>
              <ListItemText primary={link.name} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const yourFunction = () => {
  }

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{ display: isDrawerVisible? "flex": "none" }}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {links.find(link => link.path === location.pathname)?.name}
          </Typography>

          <div style={{ marginLeft: 'auto' }}>
            {links.find(link => link.path === location.pathname)?.actions?.map(entry =>
              <Button key={entry.name} onClick={entry.action} variant="contained" color='secondary'>
                <FormattedMessage id={entry.name} />
              </Button>)}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {list()}
        {
          newMessages ?
            < div style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 2, paddingBottom: 2, display: 'flex', position: 'absolute', backgroundColor: "red", borderRadius: 15 / 2, left: 30, top: messagingIconRef.current ? messagingIconRef.current?.getBoundingClientRect().top - 12 : 0, alignItems: 'center', justifyContent: 'center' }
            } >
              <Typography textAlign={'center'} style={{ color: 'white', fontSize: 12, fontWeight: 'bold' }}>{"+" + newMessages}</Typography>
            </div >
            : null}
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Box
          component="main"
          flexGrow={1}>
          <Routes>
            {links.map(link => <Route key={link.name} path={link.path} element={
              <Root isAuthenticated={isAuthenticated}
                me={me}
                component={link.component}
                yourFunction={yourFunction}
              />}
            />)}
          </Routes>
        </Box>

        <ErrorHandler parentURL={parentUrl} />
      </Main>
    </Box>

  );
}

export default App