/* tslint:disable */
/* eslint-disable */
/**
 * CounselMe Service API
 * Documentation counselme Service API v1.0
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CompanyEntityResource
 */
export interface CompanyEntityResource {
    /**
     * 
     * @type {number}
     * @memberof CompanyEntityResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyEntityResource
     */
    'identity'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyEntityResource
     */
    'state'?: CompanyEntityResourceStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyEntityResource
     */
    'position'?: CompanyEntityResourcePositionEnum;
    /**
     * 
     * @type {number}
     * @memberof CompanyEntityResource
     */
    'phone'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyEntityResource
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyEntityResource
     */
    'title'?: CompanyEntityResourceTitleEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyEntityResource
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyEntityResource
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyEntityResource
     */
    'profilePicture'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyEntityResource
     */
    'heroPicture'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyEntityResource
     */
    'headline'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CompanyEntityResourceStateEnum {
    Activated = 'ACTIVATED',
    Created = 'CREATED',
    Deactivated = 'DEACTIVATED',
    Rejected = 'REJECTED',
    WaitingForApproval = 'WAITING_FOR_APPROVAL'
}
/**
    * @export
    * @enum {string}
    */
export enum CompanyEntityResourcePositionEnum {
    BoardMember = 'BOARD_MEMBER',
    BusinessExecutive = 'BUSINESS_EXECUTIVE',
    AuthorizedRepresentative = 'AUTHORIZED_REPRESENTATIVE',
    GeneralCounsel = 'GENERAL_COUNSEL',
    SeniorLegalCounsel = 'SENIOR_LEGAL_COUNSEL',
    LegalCounsel = 'LEGAL_COUNSEL',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum CompanyEntityResourceTitleEnum {
    None = 'NONE',
    Doctor = 'DOCTOR',
    ProfessorDoctorUniversity = 'PROFESSOR_DOCTOR_UNIVERSITY',
    ProfessorDoctorUniversityOfAppliedSciences = 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'
}

/**
 * 
 * @export
 * @interface DiplomaIssuerResource
 */
export interface DiplomaIssuerResource {
    /**
     * 
     * @type {number}
     * @memberof DiplomaIssuerResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DiplomaIssuerResource
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiplomaIssuerResource
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiplomaIssuerResource
     */
    'diplomaType'?: DiplomaIssuerResourceDiplomaTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DiplomaIssuerResourceDiplomaTypeEnum {
    FirstJuridicalExamination = 'FIRST_JURIDICAL_EXAMINATION',
    SecondJuridicalExamination = 'SECOND_JURIDICAL_EXAMINATION',
    MasterOfLaws = 'MASTER_OF_LAWS'
}

/**
 * 
 * @export
 * @interface DiplomaResource
 */
export interface DiplomaResource {
    /**
     * 
     * @type {number}
     * @memberof DiplomaResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DiplomaResource
     */
    'title'?: DiplomaResourceTitleEnum;
    /**
     * 
     * @type {string}
     * @memberof DiplomaResource
     */
    'issuanceDate'?: string;
    /**
     * 
     * @type {DiplomaIssuerResource}
     * @memberof DiplomaResource
     */
    'issuer'?: DiplomaIssuerResource;
}

/**
    * @export
    * @enum {string}
    */
export enum DiplomaResourceTitleEnum {
    FirstJuridicalExamination = 'FIRST_JURIDICAL_EXAMINATION',
    SecondJuridicalExamination = 'SECOND_JURIDICAL_EXAMINATION',
    MasterOfLaws = 'MASTER_OF_LAWS'
}

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {any}
     * @memberof InlineObject
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject1
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject2
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject3
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject4
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject5
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface InviteResource
 */
export interface InviteResource {
    /**
     * 
     * @type {number}
     * @memberof InviteResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InviteResource
     */
    'identity'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteResource
     */
    'state'?: InviteResourceStateEnum;
    /**
     * 
     * @type {number}
     * @memberof InviteResource
     */
    'tenderId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum InviteResourceStateEnum {
    New = 'NEW',
    Cancelled = 'CANCELLED',
    Converted = 'CONVERTED'
}

/**
 * 
 * @export
 * @interface LawyerActivityResource
 */
export interface LawyerActivityResource {
    /**
     * 
     * @type {number}
     * @memberof LawyerActivityResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LawyerActivityResource
     */
    'activity'?: LawyerActivityResourceActivityEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum LawyerActivityResourceActivityEnum {
    TransportAndFreightForwarding = 'TRANSPORT_AND_FREIGHT_FORWARDING',
    LabourLaw = 'LABOUR_LAW',
    FinanceAndCapitalRaising = 'FINANCE_AND_CAPITAL_RAISING',
    IntellectualPropertyAndDataProtectionAndInformationTechnology = 'INTELLECTUAL_PROPERTY_AND_DATA_PROTECTION_AND_INFORMATION_TECHNOLOGY',
    CorporateLawAndMa = 'CORPORATE_LAW_AND_MA',
    RealEstateSector = 'REAL_ESTATE_SECTOR',
    InvestigationAndCommercialCriminalLaw = 'INVESTIGATION_AND_COMMERCIAL_CRIMINAL_LAW',
    InvestmentFunds = 'INVESTMENT_FUNDS',
    DisputeResolutionAndLitigationAndArbitration = 'DISPUTE_RESOLUTION_AND_LITIGATION_AND_ARBITRATION',
    RegulationAndComplianceAndPublicLaw = 'REGULATION_AND_COMPLIANCE_AND_PUBLIC_LAW',
    RestructuringAndBankruptcy = 'RESTRUCTURING_AND_BANKRUPTCY',
    TaxLaw = 'TAX_LAW',
    CompetitionAndAntitrust = 'COMPETITION_AND_ANTITRUST'
}

/**
 * 
 * @export
 * @interface LawyerCompanyEntityResource
 */
export interface LawyerCompanyEntityResource {
    /**
     * 
     * @type {number}
     * @memberof LawyerCompanyEntityResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LawyerCompanyEntityResource
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LawyerCompanyEntityResource
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof LawyerCompanyEntityResource
     */
    'address2'?: string;
    /**
     * 
     * @type {string}
     * @memberof LawyerCompanyEntityResource
     */
    'city'?: string;
    /**
     * 
     * @type {number}
     * @memberof LawyerCompanyEntityResource
     */
    'zipCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof LawyerCompanyEntityResource
     */
    'country'?: LawyerCompanyEntityResourceCountryEnum;
    /**
     * 
     * @type {number}
     * @memberof LawyerCompanyEntityResource
     */
    'phone'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum LawyerCompanyEntityResourceCountryEnum {
    De = 'DE'
}

/**
 * 
 * @export
 * @interface LawyerEntityResource
 */
export interface LawyerEntityResource {
    /**
     * 
     * @type {number}
     * @memberof LawyerEntityResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LawyerEntityResource
     */
    'identity'?: string;
    /**
     * 
     * @type {string}
     * @memberof LawyerEntityResource
     */
    'state'?: LawyerEntityResourceStateEnum;
    /**
     * 
     * @type {string}
     * @memberof LawyerEntityResource
     */
    'title'?: LawyerEntityResourceTitleEnum;
    /**
     * 
     * @type {string}
     * @memberof LawyerEntityResource
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LawyerEntityResource
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LawyerEntityResource
     */
    'profilePicture'?: string;
    /**
     * 
     * @type {string}
     * @memberof LawyerEntityResource
     */
    'heroPicture'?: string;
    /**
     * 
     * @type {string}
     * @memberof LawyerEntityResource
     */
    'headline'?: string;
    /**
     * 
     * @type {string}
     * @memberof LawyerEntityResource
     */
    'aboutMe'?: string;
    /**
     * 
     * @type {string}
     * @memberof LawyerEntityResource
     */
    'references'?: string;
    /**
     * 
     * @type {LawyerCompanyEntityResource}
     * @memberof LawyerEntityResource
     */
    'company'?: LawyerCompanyEntityResource;
    /**
     * 
     * @type {string}
     * @memberof LawyerEntityResource
     */
    'dateStartActivity'?: string;
    /**
     * 
     * @type {number}
     * @memberof LawyerEntityResource
     */
    'activityDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof LawyerEntityResource
     */
    'averageRating'?: number;
    /**
     * 
     * @type {Array<LawyerSpecialityResource>}
     * @memberof LawyerEntityResource
     */
    'specialities'?: Array<LawyerSpecialityResource>;
    /**
     * 
     * @type {Array<LawyerSpecialityResource>}
     * @memberof LawyerEntityResource
     */
    'certifiedSpecialities'?: Array<LawyerSpecialityResource>;
    /**
     * 
     * @type {Array<LawyerActivityResource>}
     * @memberof LawyerEntityResource
     */
    'activities'?: Array<LawyerActivityResource>;
    /**
     * 
     * @type {Array<LawyerLanguageResource>}
     * @memberof LawyerEntityResource
     */
    'languages'?: Array<LawyerLanguageResource>;
    /**
     * 
     * @type {Array<DiplomaResource>}
     * @memberof LawyerEntityResource
     */
    'diplomas'?: Array<DiplomaResource>;
}

/**
    * @export
    * @enum {string}
    */
export enum LawyerEntityResourceStateEnum {
    Activated = 'ACTIVATED',
    Created = 'CREATED',
    Deactivated = 'DEACTIVATED',
    Rejected = 'REJECTED',
    WaitingForApproval = 'WAITING_FOR_APPROVAL'
}
/**
    * @export
    * @enum {string}
    */
export enum LawyerEntityResourceTitleEnum {
    None = 'NONE',
    Doctor = 'DOCTOR',
    ProfessorDoctorUniversity = 'PROFESSOR_DOCTOR_UNIVERSITY',
    ProfessorDoctorUniversityOfAppliedSciences = 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'
}

/**
 * 
 * @export
 * @interface LawyerLanguageResource
 */
export interface LawyerLanguageResource {
    /**
     * 
     * @type {number}
     * @memberof LawyerLanguageResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LawyerLanguageResource
     */
    'language'?: LawyerLanguageResourceLanguageEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum LawyerLanguageResourceLanguageEnum {
    Aa = 'AA',
    Ab = 'AB',
    Ae = 'AE',
    Af = 'AF',
    Ak = 'AK',
    Am = 'AM',
    An = 'AN',
    Ar = 'AR',
    As = 'AS',
    Av = 'AV',
    Ay = 'AY',
    Az = 'AZ',
    Ba = 'BA',
    Be = 'BE',
    Bg = 'BG',
    Bi = 'BI',
    Bm = 'BM',
    Bn = 'BN',
    Bo = 'BO',
    Br = 'BR',
    Bs = 'BS',
    Ca = 'CA',
    Ce = 'CE',
    Ch = 'CH',
    Co = 'CO',
    Cr = 'CR',
    Cs = 'CS',
    Cu = 'CU',
    Cv = 'CV',
    Cy = 'CY',
    Da = 'DA',
    De = 'DE',
    Dv = 'DV',
    Dz = 'DZ',
    Ee = 'EE',
    El = 'EL',
    En = 'EN',
    Eo = 'EO',
    Es = 'ES',
    Et = 'ET',
    Eu = 'EU',
    Fa = 'FA',
    Ff = 'FF',
    Fi = 'FI',
    Fj = 'FJ',
    Fo = 'FO',
    Fr = 'FR',
    Fy = 'FY',
    Ga = 'GA',
    Gd = 'GD',
    Gl = 'GL',
    Gn = 'GN',
    Gu = 'GU',
    Gv = 'GV',
    Ha = 'HA',
    He = 'HE',
    Hi = 'HI',
    Ho = 'HO',
    Hr = 'HR',
    Ht = 'HT',
    Hu = 'HU',
    Hy = 'HY',
    Hz = 'HZ',
    Ia = 'IA',
    Id = 'ID',
    Ie = 'IE',
    Ig = 'IG',
    Ii = 'II',
    Ik = 'IK',
    Io = 'IO',
    Is = 'IS',
    It = 'IT',
    Iu = 'IU',
    Ja = 'JA',
    Jv = 'JV',
    Ka = 'KA',
    Kg = 'KG',
    Ki = 'KI',
    Kj = 'KJ',
    Kk = 'KK',
    Kl = 'KL',
    Km = 'KM',
    Kn = 'KN',
    Ko = 'KO',
    Kr = 'KR',
    Ks = 'KS',
    Ku = 'KU',
    Kv = 'KV',
    Kw = 'KW',
    Ky = 'KY',
    La = 'LA',
    Lb = 'LB',
    Lg = 'LG',
    Li = 'LI',
    Ln = 'LN',
    Lo = 'LO',
    Lt = 'LT',
    Lu = 'LU',
    Lv = 'LV',
    Mg = 'MG',
    Mh = 'MH',
    Mi = 'MI',
    Mk = 'MK',
    Ml = 'ML',
    Mn = 'MN',
    Mr = 'MR',
    Ms = 'MS',
    Mt = 'MT',
    My = 'MY',
    Na = 'NA',
    Nb = 'NB',
    Nd = 'ND',
    Ne = 'NE',
    Ng = 'NG',
    Nl = 'NL',
    Nn = 'NN',
    No = 'NO',
    Nr = 'NR',
    Nv = 'NV',
    Ny = 'NY',
    Oc = 'OC',
    Oj = 'OJ',
    Om = 'OM',
    Or = 'OR',
    Os = 'OS',
    Pa = 'PA',
    Pi = 'PI',
    Pl = 'PL',
    Ps = 'PS',
    Pt = 'PT',
    Qu = 'QU',
    Rm = 'RM',
    Rn = 'RN',
    Ro = 'RO',
    Ru = 'RU',
    Rw = 'RW',
    Sa = 'SA',
    Sc = 'SC',
    Sd = 'SD',
    Se = 'SE',
    Sg = 'SG',
    Si = 'SI',
    Sk = 'SK',
    Sl = 'SL',
    Sm = 'SM',
    Sn = 'SN',
    So = 'SO',
    Sq = 'SQ',
    Sr = 'SR',
    Ss = 'SS',
    St = 'ST',
    Su = 'SU',
    Sv = 'SV',
    Sw = 'SW',
    Ta = 'TA',
    Te = 'TE',
    Tg = 'TG',
    Th = 'TH',
    Ti = 'TI',
    Tk = 'TK',
    Tl = 'TL',
    Tn = 'TN',
    To = 'TO',
    Tr = 'TR',
    Ts = 'TS',
    Tt = 'TT',
    Tw = 'TW',
    Ty = 'TY',
    Ug = 'UG',
    Uk = 'UK',
    Ur = 'UR',
    Uz = 'UZ',
    Ve = 'VE',
    Vi = 'VI',
    Vo = 'VO',
    Wa = 'WA',
    Wo = 'WO',
    Xh = 'XH',
    Yi = 'YI',
    Yo = 'YO',
    Za = 'ZA',
    Zh = 'ZH',
    Zu = 'ZU'
}

/**
 * 
 * @export
 * @interface LawyerOfferDocumentResource
 */
export interface LawyerOfferDocumentResource {
    /**
     * 
     * @type {number}
     * @memberof LawyerOfferDocumentResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LawyerOfferDocumentResource
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof LawyerOfferDocumentResource
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof LawyerOfferDocumentResource
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface LawyerOfferResource
 */
export interface LawyerOfferResource {
    /**
     * 
     * @type {number}
     * @memberof LawyerOfferResource
     */
    'id'?: number;
    /**
     * 
     * @type {CompanyEntityResource}
     * @memberof LawyerOfferResource
     */
    'company'?: CompanyEntityResource;
    /**
     * 
     * @type {string}
     * @memberof LawyerOfferResource
     */
    'state'?: LawyerOfferResourceStateEnum;
    /**
     * 
     * @type {string}
     * @memberof LawyerOfferResource
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof LawyerOfferResource
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof LawyerOfferResource
     */
    'remainingTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof LawyerOfferResource
     */
    'type'?: LawyerOfferResourceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof LawyerOfferResource
     */
    'businessValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof LawyerOfferResource
     */
    'offerValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof LawyerOfferResource
     */
    'offerDescription'?: string;
    /**
     * 
     * @type {LawyerEntityResource}
     * @memberof LawyerOfferResource
     */
    'lawyer'?: LawyerEntityResource;
    /**
     * 
     * @type {Array<LawyerOfferDocumentResource>}
     * @memberof LawyerOfferResource
     */
    'documents'?: Array<LawyerOfferDocumentResource>;
    /**
     * 
     * @type {Array<TenderDocumentResource>}
     * @memberof LawyerOfferResource
     */
    'tenderDocuments'?: Array<TenderDocumentResource>;
    /**
     * 
     * @type {number}
     * @memberof LawyerOfferResource
     */
    'ratingCommunication'?: number;
    /**
     * 
     * @type {number}
     * @memberof LawyerOfferResource
     */
    'ratingPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof LawyerOfferResource
     */
    'ratingPunctuality'?: number;
    /**
     * 
     * @type {number}
     * @memberof LawyerOfferResource
     */
    'ratingQuality'?: number;
    /**
     * 
     * @type {string}
     * @memberof LawyerOfferResource
     */
    'review'?: string;
    /**
     * 
     * @type {string}
     * @memberof LawyerOfferResource
     */
    'reviewComment'?: string;
    /**
     * 
     * @type {number}
     * @memberof LawyerOfferResource
     */
    'averageRating'?: number;
    /**
     * 
     * @type {CompanyEntityResource}
     * @memberof LawyerOfferResource
     */
    'client'?: CompanyEntityResource;
}

/**
    * @export
    * @enum {string}
    */
export enum LawyerOfferResourceStateEnum {
    Answered = 'ANSWERED',
    Cancelled = 'CANCELLED',
    Invited = 'INVITED',
    InvitedToPitch = 'INVITED_TO_PITCH',
    NotInterested = 'NOT_INTERESTED',
    NotSelected = 'NOT_SELECTED',
    ShortList = 'SHORT_LIST',
    Selected = 'SELECTED',
    Closed = 'CLOSED'
}
/**
    * @export
    * @enum {string}
    */
export enum LawyerOfferResourceTypeEnum {
    Pitch = 'PITCH',
    Proposal = 'PROPOSAL'
}

/**
 * 
 * @export
 * @interface LawyerSpecialityResource
 */
export interface LawyerSpecialityResource {
    /**
     * 
     * @type {number}
     * @memberof LawyerSpecialityResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LawyerSpecialityResource
     */
    'speciality'?: LawyerSpecialityResourceSpecialityEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum LawyerSpecialityResourceSpecialityEnum {
    Undefined = 'UNDEFINED',
    Administrative = 'ADMINISTRATIVE',
    BankingAndCapitalMarkets = 'BANKING_AND_CAPITAL_MARKETS',
    Bankruptcy = 'BANKRUPTCY',
    BuildingAndArchitect = 'BUILDING_AND_ARCHITECT',
    CertifiedMediaAndCopyright = 'CERTIFIED_MEDIA_AND_COPYRIGHT',
    CommercialAndCorporate = 'COMMERCIAL_AND_CORPORATE',
    Criminal = 'CRIMINAL',
    InformationTechnology = 'INFORMATION_TECHNOLOGY',
    Insurance = 'INSURANCE',
    IntellectualProperty = 'INTELLECTUAL_PROPERTY',
    InternationalBusiness = 'INTERNATIONAL_BUSINESS',
    Labour = 'LABOUR',
    Procurement = 'PROCUREMENT',
    Tax = 'TAX',
    TransportAndFreightForwarding = 'TRANSPORT_AND_FREIGHT_FORWARDING'
}

/**
 * 
 * @export
 * @interface MeResource
 */
export interface MeResource {
    /**
     * 
     * @type {string}
     * @memberof MeResource
     */
    'identity'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeResource
     */
    'type'?: MeResourceTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MeResource
     */
    'isActivated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeResource
     */
    'activated'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum MeResourceTypeEnum {
    Company = 'COMPANY',
    CompanyNotYetCreated = 'COMPANY_NOT_YET_CREATED',
    Lawyer = 'LAWYER',
    LawyerNotYetCreated = 'LAWYER_NOT_YET_CREATED',
    Unknown = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface MessagingDocumentResource
 */
export interface MessagingDocumentResource {
    /**
     * 
     * @type {number}
     * @memberof MessagingDocumentResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessagingDocumentResource
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessagingDocumentResource
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessagingDocumentResource
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface MessagingMessageResource
 */
export interface MessagingMessageResource {
    /**
     * 
     * @type {number}
     * @memberof MessagingMessageResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessagingMessageResource
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessagingMessageResource
     */
    'identity'?: string;
}
/**
 * 
 * @export
 * @interface MessagingThreadParticipantResource
 */
export interface MessagingThreadParticipantResource {
    /**
     * 
     * @type {string}
     * @memberof MessagingThreadParticipantResource
     */
    'identity'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessagingThreadParticipantResource
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessagingThreadParticipantResource
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessagingThreadParticipantResource
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessagingThreadParticipantResource
     */
    'profilePicture'?: string;
}
/**
 * 
 * @export
 * @interface MessagingThreadResource
 */
export interface MessagingThreadResource {
    /**
     * 
     * @type {number}
     * @memberof MessagingThreadResource
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MessagingThreadResource
     */
    'tenderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessagingThreadResource
     */
    'title'?: string;
    /**
     * 
     * @type {Set<MessagingThreadParticipantResource>}
     * @memberof MessagingThreadResource
     */
    'participants'?: Set<MessagingThreadParticipantResource>;
    /**
     * 
     * @type {number}
     * @memberof MessagingThreadResource
     */
    'hasNewMessages'?: number;
}
/**
 * 
 * @export
 * @interface PageCompanyEntityResource
 */
export interface PageCompanyEntityResource {
    /**
     * 
     * @type {number}
     * @memberof PageCompanyEntityResource
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyEntityResource
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyEntityResource
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyEntityResource
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyEntityResource
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Array<CompanyEntityResource>}
     * @memberof PageCompanyEntityResource
     */
    'content'?: Array<CompanyEntityResource>;
    /**
     * 
     * @type {Sort}
     * @memberof PageCompanyEntityResource
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageCompanyEntityResource
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCompanyEntityResource
     */
    'last'?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageCompanyEntityResource
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageCompanyEntityResource
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageInviteResource
 */
export interface PageInviteResource {
    /**
     * 
     * @type {number}
     * @memberof PageInviteResource
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInviteResource
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInviteResource
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInviteResource
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInviteResource
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Array<InviteResource>}
     * @memberof PageInviteResource
     */
    'content'?: Array<InviteResource>;
    /**
     * 
     * @type {Sort}
     * @memberof PageInviteResource
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageInviteResource
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInviteResource
     */
    'last'?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageInviteResource
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageInviteResource
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageLawyerEntityResource
 */
export interface PageLawyerEntityResource {
    /**
     * 
     * @type {number}
     * @memberof PageLawyerEntityResource
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLawyerEntityResource
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLawyerEntityResource
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLawyerEntityResource
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLawyerEntityResource
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Array<LawyerEntityResource>}
     * @memberof PageLawyerEntityResource
     */
    'content'?: Array<LawyerEntityResource>;
    /**
     * 
     * @type {Sort}
     * @memberof PageLawyerEntityResource
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageLawyerEntityResource
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLawyerEntityResource
     */
    'last'?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLawyerEntityResource
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageLawyerEntityResource
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageLawyerOfferResource
 */
export interface PageLawyerOfferResource {
    /**
     * 
     * @type {number}
     * @memberof PageLawyerOfferResource
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLawyerOfferResource
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLawyerOfferResource
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLawyerOfferResource
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLawyerOfferResource
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Array<LawyerOfferResource>}
     * @memberof PageLawyerOfferResource
     */
    'content'?: Array<LawyerOfferResource>;
    /**
     * 
     * @type {Sort}
     * @memberof PageLawyerOfferResource
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageLawyerOfferResource
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLawyerOfferResource
     */
    'last'?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLawyerOfferResource
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageLawyerOfferResource
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageMessagingMessageResource
 */
export interface PageMessagingMessageResource {
    /**
     * 
     * @type {number}
     * @memberof PageMessagingMessageResource
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMessagingMessageResource
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMessagingMessageResource
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMessagingMessageResource
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMessagingMessageResource
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Array<MessagingMessageResource>}
     * @memberof PageMessagingMessageResource
     */
    'content'?: Array<MessagingMessageResource>;
    /**
     * 
     * @type {Sort}
     * @memberof PageMessagingMessageResource
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageMessagingMessageResource
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMessagingMessageResource
     */
    'last'?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageMessagingMessageResource
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageMessagingMessageResource
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageMessagingThreadResource
 */
export interface PageMessagingThreadResource {
    /**
     * 
     * @type {number}
     * @memberof PageMessagingThreadResource
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMessagingThreadResource
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMessagingThreadResource
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMessagingThreadResource
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMessagingThreadResource
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Array<MessagingThreadResource>}
     * @memberof PageMessagingThreadResource
     */
    'content'?: Array<MessagingThreadResource>;
    /**
     * 
     * @type {Sort}
     * @memberof PageMessagingThreadResource
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageMessagingThreadResource
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMessagingThreadResource
     */
    'last'?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageMessagingThreadResource
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageMessagingThreadResource
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageTenderResource
 */
export interface PageTenderResource {
    /**
     * 
     * @type {number}
     * @memberof PageTenderResource
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTenderResource
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTenderResource
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTenderResource
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTenderResource
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Array<TenderResource>}
     * @memberof PageTenderResource
     */
    'content'?: Array<TenderResource>;
    /**
     * 
     * @type {Sort}
     * @memberof PageTenderResource
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageTenderResource
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTenderResource
     */
    'last'?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTenderResource
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageTenderResource
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageableObject
     */
    'sort'?: Sort;
}
/**
 * 
 * @export
 * @interface PairLAWYEROFFERSTATEListLAWYEROFFERSTATE
 */
export interface PairLAWYEROFFERSTATEListLAWYEROFFERSTATE {
    /**
     * 
     * @type {string}
     * @memberof PairLAWYEROFFERSTATEListLAWYEROFFERSTATE
     */
    'value0'?: PairLAWYEROFFERSTATEListLAWYEROFFERSTATEValue0Enum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PairLAWYEROFFERSTATEListLAWYEROFFERSTATE
     */
    'value1'?: Array<PairLAWYEROFFERSTATEListLAWYEROFFERSTATEValue1Enum>;
    /**
     * 
     * @type {number}
     * @memberof PairLAWYEROFFERSTATEListLAWYEROFFERSTATE
     */
    'size'?: number;
    /**
     * 
     * @type {PairObjectListLAWYEROFFERSTATE}
     * @memberof PairLAWYEROFFERSTATEListLAWYEROFFERSTATE
     */
    'at0'?: PairObjectListLAWYEROFFERSTATE;
    /**
     * 
     * @type {PairLAWYEROFFERSTATEObject}
     * @memberof PairLAWYEROFFERSTATEListLAWYEROFFERSTATE
     */
    'at1'?: PairLAWYEROFFERSTATEObject;
}

/**
    * @export
    * @enum {string}
    */
export enum PairLAWYEROFFERSTATEListLAWYEROFFERSTATEValue0Enum {
    Answered = 'ANSWERED',
    Cancelled = 'CANCELLED',
    Invited = 'INVITED',
    InvitedToPitch = 'INVITED_TO_PITCH',
    NotInterested = 'NOT_INTERESTED',
    NotSelected = 'NOT_SELECTED',
    ShortList = 'SHORT_LIST',
    Selected = 'SELECTED',
    Closed = 'CLOSED'
}
/**
    * @export
    * @enum {string}
    */
export enum PairLAWYEROFFERSTATEListLAWYEROFFERSTATEValue1Enum {
    Answered = 'ANSWERED',
    Cancelled = 'CANCELLED',
    Invited = 'INVITED',
    InvitedToPitch = 'INVITED_TO_PITCH',
    NotInterested = 'NOT_INTERESTED',
    NotSelected = 'NOT_SELECTED',
    ShortList = 'SHORT_LIST',
    Selected = 'SELECTED',
    Closed = 'CLOSED'
}

/**
 * 
 * @export
 * @interface PairLAWYEROFFERSTATEObject
 */
export interface PairLAWYEROFFERSTATEObject {
    /**
     * 
     * @type {string}
     * @memberof PairLAWYEROFFERSTATEObject
     */
    'value0'?: PairLAWYEROFFERSTATEObjectValue0Enum;
    /**
     * 
     * @type {object}
     * @memberof PairLAWYEROFFERSTATEObject
     */
    'value1'?: object;
    /**
     * 
     * @type {number}
     * @memberof PairLAWYEROFFERSTATEObject
     */
    'size'?: number;
    /**
     * 
     * @type {PairObjectObject}
     * @memberof PairLAWYEROFFERSTATEObject
     */
    'at0'?: PairObjectObject;
    /**
     * 
     * @type {PairLAWYEROFFERSTATEObject}
     * @memberof PairLAWYEROFFERSTATEObject
     */
    'at1'?: PairLAWYEROFFERSTATEObject;
}

/**
    * @export
    * @enum {string}
    */
export enum PairLAWYEROFFERSTATEObjectValue0Enum {
    Answered = 'ANSWERED',
    Cancelled = 'CANCELLED',
    Invited = 'INVITED',
    InvitedToPitch = 'INVITED_TO_PITCH',
    NotInterested = 'NOT_INTERESTED',
    NotSelected = 'NOT_SELECTED',
    ShortList = 'SHORT_LIST',
    Selected = 'SELECTED',
    Closed = 'CLOSED'
}

/**
 * 
 * @export
 * @interface PairObjectListLAWYEROFFERSTATE
 */
export interface PairObjectListLAWYEROFFERSTATE {
    /**
     * 
     * @type {object}
     * @memberof PairObjectListLAWYEROFFERSTATE
     */
    'value0'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof PairObjectListLAWYEROFFERSTATE
     */
    'value1'?: Array<PairObjectListLAWYEROFFERSTATEValue1Enum>;
    /**
     * 
     * @type {number}
     * @memberof PairObjectListLAWYEROFFERSTATE
     */
    'size'?: number;
    /**
     * 
     * @type {PairObjectListLAWYEROFFERSTATE}
     * @memberof PairObjectListLAWYEROFFERSTATE
     */
    'at0'?: PairObjectListLAWYEROFFERSTATE;
    /**
     * 
     * @type {PairObjectObject}
     * @memberof PairObjectListLAWYEROFFERSTATE
     */
    'at1'?: PairObjectObject;
}

/**
    * @export
    * @enum {string}
    */
export enum PairObjectListLAWYEROFFERSTATEValue1Enum {
    Answered = 'ANSWERED',
    Cancelled = 'CANCELLED',
    Invited = 'INVITED',
    InvitedToPitch = 'INVITED_TO_PITCH',
    NotInterested = 'NOT_INTERESTED',
    NotSelected = 'NOT_SELECTED',
    ShortList = 'SHORT_LIST',
    Selected = 'SELECTED',
    Closed = 'CLOSED'
}

/**
 * 
 * @export
 * @interface PairObjectObject
 */
export interface PairObjectObject {
    /**
     * 
     * @type {object}
     * @memberof PairObjectObject
     */
    'value0'?: object;
    /**
     * 
     * @type {object}
     * @memberof PairObjectObject
     */
    'value1'?: object;
    /**
     * 
     * @type {number}
     * @memberof PairObjectObject
     */
    'size'?: number;
    /**
     * 
     * @type {PairObjectObject}
     * @memberof PairObjectObject
     */
    'at0'?: PairObjectObject;
    /**
     * 
     * @type {PairObjectObject}
     * @memberof PairObjectObject
     */
    'at1'?: PairObjectObject;
}
/**
 * 
 * @export
 * @interface ReviewResource
 */
export interface ReviewResource {
    /**
     * 
     * @type {number}
     * @memberof ReviewResource
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewResource
     */
    'averageRating'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReviewResource
     */
    'review'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewResource
     */
    'reviewComment'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewResource
     */
    'company'?: string;
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface TenderDocumentResource
 */
export interface TenderDocumentResource {
    /**
     * 
     * @type {number}
     * @memberof TenderDocumentResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenderDocumentResource
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderDocumentResource
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderDocumentResource
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface TenderResource
 */
export interface TenderResource {
    /**
     * 
     * @type {number}
     * @memberof TenderResource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenderResource
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderResource
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderResource
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderResource
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderResource
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderResource
     */
    'state'?: TenderResourceStateEnum;
    /**
     * 
     * @type {number}
     * @memberof TenderResource
     */
    'remainingTimeToAnswer'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenderResource
     */
    'type'?: TenderResourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TenderResource
     */
    'contact'?: string;
    /**
     * 
     * @type {number}
     * @memberof TenderResource
     */
    'businessValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenderResource
     */
    'deliberationDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenderResource
     */
    'remainingTimeDeliberation'?: number;
    /**
     * 
     * @type {LawyerOfferResource}
     * @memberof TenderResource
     */
    'selectedOffer'?: LawyerOfferResource;
}

/**
    * @export
    * @enum {string}
    */
export enum TenderResourceStateEnum {
    Canceled = 'CANCELED',
    Deleted = 'DELETED',
    Done = 'DONE',
    Draft = 'DRAFT',
    InviteSent = 'INVITE_SENT',
    OfferSelected = 'OFFER_SELECTED',
    Deliberate = 'DELIBERATE',
    WaitingStartDate = 'WAITING_START_DATE'
}
/**
    * @export
    * @enum {string}
    */
export enum TenderResourceTypeEnum {
    Pitch = 'PITCH',
    Proposal = 'PROPOSAL'
}


/**
 * AdminControllerApi - axios parameter creator
 * @export
 */
export const AdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocuments: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadDocuments', 'id', id)
            const localVarPath = `/admin/lawyer/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCompanyById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findCompanyById', 'id', id)
            const localVarPath = `/admin/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLawyerById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findLawyerById', 'id', id)
            const localVarPath = `/admin/lawyer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesPaginated: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitesPaginated: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/invites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawyersPaginated: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/lawyers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {'ACTIVATED' | 'CREATED' | 'DEACTIVATED' | 'REJECTED' | 'WAITING_FOR_APPROVAL'} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyState: async (id: number, state: 'ACTIVATED' | 'CREATED' | 'DEACTIVATED' | 'REJECTED' | 'WAITING_FOR_APPROVAL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putCompanyState', 'id', id)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('putCompanyState', 'state', state)
            const localVarPath = `/admin/companies/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {'ACTIVATED' | 'CREATED' | 'DEACTIVATED' | 'REJECTED' | 'WAITING_FOR_APPROVAL'} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLawyerState: async (id: number, state: 'ACTIVATED' | 'CREATED' | 'DEACTIVATED' | 'REJECTED' | 'WAITING_FOR_APPROVAL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putLawyerState', 'id', id)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('putLawyerState', 'state', state)
            const localVarPath = `/admin/lawyers/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminControllerApi - functional programming interface
 * @export
 */
export const AdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDocuments(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDocuments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCompanyById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCompanyById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLawyerById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLawyerById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCompanyEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesPaginated(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvitesPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageInviteResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitesPaginated(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLawyersPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLawyerEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLawyersPaginated(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {'ACTIVATED' | 'CREATED' | 'DEACTIVATED' | 'REJECTED' | 'WAITING_FOR_APPROVAL'} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCompanyState(id: number, state: 'ACTIVATED' | 'CREATED' | 'DEACTIVATED' | 'REJECTED' | 'WAITING_FOR_APPROVAL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCompanyState(id, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {'ACTIVATED' | 'CREATED' | 'DEACTIVATED' | 'REJECTED' | 'WAITING_FOR_APPROVAL'} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLawyerState(id: number, state: 'ACTIVATED' | 'CREATED' | 'DEACTIVATED' | 'REJECTED' | 'WAITING_FOR_APPROVAL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLawyerState(id, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminControllerApi - factory interface
 * @export
 */
export const AdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocuments(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.downloadDocuments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCompanyById(id: number, options?: any): AxiosPromise<CompanyEntityResource> {
            return localVarFp.findCompanyById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLawyerById(id: number, options?: any): AxiosPromise<LawyerEntityResource> {
            return localVarFp.findLawyerById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesPaginated(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageCompanyEntityResource> {
            return localVarFp.getCompaniesPaginated(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitesPaginated(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageInviteResource> {
            return localVarFp.getInvitesPaginated(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawyersPaginated(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLawyerEntityResource> {
            return localVarFp.getLawyersPaginated(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {'ACTIVATED' | 'CREATED' | 'DEACTIVATED' | 'REJECTED' | 'WAITING_FOR_APPROVAL'} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyState(id: number, state: 'ACTIVATED' | 'CREATED' | 'DEACTIVATED' | 'REJECTED' | 'WAITING_FOR_APPROVAL', options?: any): AxiosPromise<CompanyEntityResource> {
            return localVarFp.putCompanyState(id, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {'ACTIVATED' | 'CREATED' | 'DEACTIVATED' | 'REJECTED' | 'WAITING_FOR_APPROVAL'} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLawyerState(id: number, state: 'ACTIVATED' | 'CREATED' | 'DEACTIVATED' | 'REJECTED' | 'WAITING_FOR_APPROVAL', options?: any): AxiosPromise<LawyerEntityResource> {
            return localVarFp.putLawyerState(id, state, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminControllerApi - object-oriented interface
 * @export
 * @class AdminControllerApi
 * @extends {BaseAPI}
 */
export class AdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public downloadDocuments(id: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).downloadDocuments(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public findCompanyById(id: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).findCompanyById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public findLawyerById(id: number, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).findLawyerById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getCompaniesPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getCompaniesPaginated(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getInvitesPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getInvitesPaginated(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getLawyersPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getLawyersPaginated(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {'ACTIVATED' | 'CREATED' | 'DEACTIVATED' | 'REJECTED' | 'WAITING_FOR_APPROVAL'} state 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putCompanyState(id: number, state: 'ACTIVATED' | 'CREATED' | 'DEACTIVATED' | 'REJECTED' | 'WAITING_FOR_APPROVAL', options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putCompanyState(id, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {'ACTIVATED' | 'CREATED' | 'DEACTIVATED' | 'REJECTED' | 'WAITING_FOR_APPROVAL'} state 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public putLawyerState(id: number, state: 'ACTIVATED' | 'CREATED' | 'DEACTIVATED' | 'REJECTED' | 'WAITING_FOR_APPROVAL', options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).putLawyerState(id, state, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticatedControllerApi - axios parameter creator
 * @export
 */
export const AuthenticatedControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} idThread 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteThreadAcknowledgments: async (idThread: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idThread' is not null or undefined
            assertParamExists('deleteThreadAcknowledgments', 'idThread', idThread)
            const localVarPath = `/authenticated/messaging/threads/{idThread}/acknowledgments`
                .replace(`{${"idThread"}}`, encodeURIComponent(String(idThread)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} idThread 
         * @param {number} idDocument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMessagingDocument: async (idThread: number, idDocument: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idThread' is not null or undefined
            assertParamExists('downloadMessagingDocument', 'idThread', idThread)
            // verify required parameter 'idDocument' is not null or undefined
            assertParamExists('downloadMessagingDocument', 'idDocument', idDocument)
            const localVarPath = `/authenticated/messaging/threads/{idThread}/documents/{idDocument}`
                .replace(`{${"idThread"}}`, encodeURIComponent(String(idThread)))
                .replace(`{${"idDocument"}}`, encodeURIComponent(String(idDocument)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagingMessagesPaginated: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMessagingMessagesPaginated', 'id', id)
            const localVarPath = `/authenticated/messaging/threads/{id}/messages`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'>} [offerStates] 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagingThreadsPaginated: async (offerStates?: Array<'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'>, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/authenticated/messaging/threads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offerStates) {
                localVarQueryParameter['offerStates'] = offerStates;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasNewMessages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/authenticated/messaging/hasNewMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} idThread 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasThreadNewMessages: async (idThread: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idThread' is not null or undefined
            assertParamExists('hasThreadNewMessages', 'idThread', idThread)
            const localVarPath = `/authenticated/messaging/threads/{idThread}/hasNewMessages`
                .replace(`{${"idThread"}}`, encodeURIComponent(String(idThread)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/authenticated/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMessagingMessage: async (id: number, message: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putMessagingMessage', 'id', id)
            // verify required parameter 'message' is not null or undefined
            assertParamExists('putMessagingMessage', 'message', message)
            const localVarPath = `/authenticated/messaging/threads/{id}/messages`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (message !== undefined) {
                localVarQueryParameter['message'] = message;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {InlineObject5} [inlineObject5] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMessagingDocument: async (id: number, inlineObject5?: InlineObject5, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadMessagingDocument', 'id', id)
            const localVarPath = `/authenticated/messaging/threads/{id}/upload`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject5, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticatedControllerApi - functional programming interface
 * @export
 */
export const AuthenticatedControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticatedControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} idThread 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteThreadAcknowledgments(idThread: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteThreadAcknowledgments(idThread, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} idThread 
         * @param {number} idDocument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadMessagingDocument(idThread: number, idDocument: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadMessagingDocument(idThread, idDocument, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessagingMessagesPaginated(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMessagingMessageResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessagingMessagesPaginated(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'>} [offerStates] 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessagingThreadsPaginated(offerStates?: Array<'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMessagingThreadResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessagingThreadsPaginated(offerStates, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasNewMessages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasNewMessages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} idThread 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasThreadNewMessages(idThread: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasThreadNewMessages(idThread, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async me2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.me2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMessagingMessage(id: number, message: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagingMessageResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMessagingMessage(id, message, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {InlineObject5} [inlineObject5] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMessagingDocument(id: number, inlineObject5?: InlineObject5, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagingDocumentResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadMessagingDocument(id, inlineObject5, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticatedControllerApi - factory interface
 * @export
 */
export const AuthenticatedControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticatedControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} idThread 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteThreadAcknowledgments(idThread: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteThreadAcknowledgments(idThread, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} idThread 
         * @param {number} idDocument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMessagingDocument(idThread: number, idDocument: number, options?: any): AxiosPromise<any> {
            return localVarFp.downloadMessagingDocument(idThread, idDocument, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagingMessagesPaginated(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMessagingMessageResource> {
            return localVarFp.getMessagingMessagesPaginated(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'>} [offerStates] 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagingThreadsPaginated(offerStates?: Array<'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMessagingThreadResource> {
            return localVarFp.getMessagingThreadsPaginated(offerStates, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasNewMessages(options?: any): AxiosPromise<number> {
            return localVarFp.hasNewMessages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} idThread 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasThreadNewMessages(idThread: number, options?: any): AxiosPromise<number> {
            return localVarFp.hasThreadNewMessages(idThread, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me2(options?: any): AxiosPromise<MeResource> {
            return localVarFp.me2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMessagingMessage(id: number, message: string, options?: any): AxiosPromise<MessagingMessageResource> {
            return localVarFp.putMessagingMessage(id, message, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {InlineObject5} [inlineObject5] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMessagingDocument(id: number, inlineObject5?: InlineObject5, options?: any): AxiosPromise<MessagingDocumentResource> {
            return localVarFp.uploadMessagingDocument(id, inlineObject5, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticatedControllerApi - object-oriented interface
 * @export
 * @class AuthenticatedControllerApi
 * @extends {BaseAPI}
 */
export class AuthenticatedControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} idThread 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticatedControllerApi
     */
    public deleteThreadAcknowledgments(idThread: number, options?: AxiosRequestConfig) {
        return AuthenticatedControllerApiFp(this.configuration).deleteThreadAcknowledgments(idThread, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} idThread 
     * @param {number} idDocument 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticatedControllerApi
     */
    public downloadMessagingDocument(idThread: number, idDocument: number, options?: AxiosRequestConfig) {
        return AuthenticatedControllerApiFp(this.configuration).downloadMessagingDocument(idThread, idDocument, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [page] Page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticatedControllerApi
     */
    public getMessagingMessagesPaginated(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return AuthenticatedControllerApiFp(this.configuration).getMessagingMessagesPaginated(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'>} [offerStates] 
     * @param {number} [page] Page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticatedControllerApi
     */
    public getMessagingThreadsPaginated(offerStates?: Array<'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return AuthenticatedControllerApiFp(this.configuration).getMessagingThreadsPaginated(offerStates, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticatedControllerApi
     */
    public hasNewMessages(options?: AxiosRequestConfig) {
        return AuthenticatedControllerApiFp(this.configuration).hasNewMessages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} idThread 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticatedControllerApi
     */
    public hasThreadNewMessages(idThread: number, options?: AxiosRequestConfig) {
        return AuthenticatedControllerApiFp(this.configuration).hasThreadNewMessages(idThread, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticatedControllerApi
     */
    public me2(options?: AxiosRequestConfig) {
        return AuthenticatedControllerApiFp(this.configuration).me2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} message 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticatedControllerApi
     */
    public putMessagingMessage(id: number, message: string, options?: AxiosRequestConfig) {
        return AuthenticatedControllerApiFp(this.configuration).putMessagingMessage(id, message, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {InlineObject5} [inlineObject5] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticatedControllerApi
     */
    public uploadMessagingDocument(id: number, inlineObject5?: InlineObject5, options?: AxiosRequestConfig) {
        return AuthenticatedControllerApiFp(this.configuration).uploadMessagingDocument(id, inlineObject5, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyControllerApi - axios parameter creator
 * @export
 */
export const CompanyControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTender: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTender', 'id', id)
            const localVarPath = `/company/tenders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenderDocument: async (id: number, documentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTenderDocument', 'id', id)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteTenderDocument', 'documentId', documentId)
            const localVarPath = `/company/tenders/{id}/documents/{documentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTenderDocument: async (id: number, documentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadTenderDocument', 'id', id)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('downloadTenderDocument', 'documentId', documentId)
            const localVarPath = `/company/tenders/{id}/documents/{documentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTenderLawyerOfferDocuments: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadTenderLawyerOfferDocuments', 'id', id)
            const localVarPath = `/company/offers/{id}/documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<LawyerActivityResource>} lawyerActivityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawyers: async (lawyerActivityResource: Array<LawyerActivityResource>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lawyerActivityResource' is not null or undefined
            assertParamExists('getLawyers', 'lawyerActivityResource', lawyerActivityResource)
            const localVarPath = `/company/lawyers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lawyerActivityResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTender: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTender', 'id', id)
            const localVarPath = `/company/tenders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenderDocuments: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTenderDocuments', 'id', id)
            const localVarPath = `/company/tenders/{id}/documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenderInvitesPaginated: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTenderInvitesPaginated', 'id', id)
            const localVarPath = `/company/tenders/{id}/invites`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenderLawyers: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTenderLawyers', 'id', id)
            const localVarPath = `/company/tenders/{id}/lawyers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenderNextStates: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTenderNextStates', 'id', id)
            const localVarPath = `/company/tenders/{id}/nextstates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenderOffers: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTenderOffers', 'id', id)
            const localVarPath = `/company/tenders/{id}/offers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenderOffersTransitions: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTenderOffersTransitions', 'id', id)
            const localVarPath = `/company/tenders/{id}/offers/transitions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'>} [tenderStates] 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTendersPaginated: async (tenderStates?: Array<'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'>, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/tenders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenderStates) {
                localVarQueryParameter['tenderStates'] = tenderStates;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestapproval: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/requestapproval`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'PITCH' | 'PROPOSAL'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTender: async (type: 'PITCH' | 'PROPOSAL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('postTender', 'type', type)
            const localVarPath = `/company/tender`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LawyerEntityResource} lawyerEntityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTenderAddLawyer: async (id: number, lawyerEntityResource: LawyerEntityResource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postTenderAddLawyer', 'id', id)
            // verify required parameter 'lawyerEntityResource' is not null or undefined
            assertParamExists('postTenderAddLawyer', 'lawyerEntityResource', lawyerEntityResource)
            const localVarPath = `/company/tenders/{id}/add_lawyer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lawyerEntityResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LawyerEntityResource} lawyerEntityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTenderRemoveLawyer: async (id: number, lawyerEntityResource: LawyerEntityResource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postTenderRemoveLawyer', 'id', id)
            // verify required parameter 'lawyerEntityResource' is not null or undefined
            assertParamExists('postTenderRemoveLawyer', 'lawyerEntityResource', lawyerEntityResource)
            const localVarPath = `/company/tenders/{id}/remove_lawyer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lawyerEntityResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCancelOffer: async (offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('putCancelOffer', 'offerId', offerId)
            const localVarPath = `/company/offers/{offerId}/cancel`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} ratingQuality 
         * @param {number} ratingPunctuality 
         * @param {number} ratingCommunication 
         * @param {number} ratingPrice 
         * @param {string} review 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCloseMandate: async (id: number, ratingQuality: number, ratingPunctuality: number, ratingCommunication: number, ratingPrice: number, review: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putCloseMandate', 'id', id)
            // verify required parameter 'ratingQuality' is not null or undefined
            assertParamExists('putCloseMandate', 'ratingQuality', ratingQuality)
            // verify required parameter 'ratingPunctuality' is not null or undefined
            assertParamExists('putCloseMandate', 'ratingPunctuality', ratingPunctuality)
            // verify required parameter 'ratingCommunication' is not null or undefined
            assertParamExists('putCloseMandate', 'ratingCommunication', ratingCommunication)
            // verify required parameter 'ratingPrice' is not null or undefined
            assertParamExists('putCloseMandate', 'ratingPrice', ratingPrice)
            // verify required parameter 'review' is not null or undefined
            assertParamExists('putCloseMandate', 'review', review)
            const localVarPath = `/company/tenders/{id}/close`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ratingQuality !== undefined) {
                localVarQueryParameter['ratingQuality'] = ratingQuality;
            }

            if (ratingPunctuality !== undefined) {
                localVarQueryParameter['ratingPunctuality'] = ratingPunctuality;
            }

            if (ratingCommunication !== undefined) {
                localVarQueryParameter['ratingCommunication'] = ratingCommunication;
            }

            if (ratingPrice !== undefined) {
                localVarQueryParameter['ratingPrice'] = ratingPrice;
            }

            if (review !== undefined) {
                localVarQueryParameter['review'] = review;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {number} [phone] 
         * @param {string} [picture] 
         * @param {string} [hero] 
         * @param {'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'} [title] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [headline] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDetails: async (name: string, phone?: number, picture?: string, hero?: string, title?: 'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES', firstName?: string, lastName?: string, headline?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('putDetails', 'name', name)
            const localVarPath = `/company/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (picture !== undefined) {
                localVarQueryParameter['picture'] = picture;
            }

            if (hero !== undefined) {
                localVarQueryParameter['hero'] = hero;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (headline !== undefined) {
                localVarQueryParameter['headline'] = headline;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'BOARD_MEMBER' | 'BUSINESS_EXECUTIVE' | 'AUTHORIZED_REPRESENTATIVE' | 'GENERAL_COUNSEL' | 'SENIOR_LEGAL_COUNSEL' | 'LEGAL_COUNSEL' | 'OTHER'} position 
         * @param {'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'} [title] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIdentityDetails1: async (position: 'BOARD_MEMBER' | 'BUSINESS_EXECUTIVE' | 'AUTHORIZED_REPRESENTATIVE' | 'GENERAL_COUNSEL' | 'SENIOR_LEGAL_COUNSEL' | 'LEGAL_COUNSEL' | 'OTHER', title?: 'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES', firstName?: string, lastName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'position' is not null or undefined
            assertParamExists('putIdentityDetails1', 'position', position)
            const localVarPath = `/company/identitydetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (position !== undefined) {
                localVarQueryParameter['position'] = position;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInviteToPitch: async (offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('putInviteToPitch', 'offerId', offerId)
            const localVarPath = `/company/offers/{offerId}/inviteToPitch`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offerId 
         * @param {'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLawyerOfferState: async (offerId: number, state: 'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('putLawyerOfferState', 'offerId', offerId)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('putLawyerOfferState', 'state', state)
            const localVarPath = `/company/offers/{offerId}/state`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRejectOffer: async (offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('putRejectOffer', 'offerId', offerId)
            const localVarPath = `/company/offers/{offerId}/reject`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putShortListOffer: async (offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('putShortListOffer', 'offerId', offerId)
            const localVarPath = `/company/offers/{offerId}/shortlist`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStartOffer: async (offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('putStartOffer', 'offerId', offerId)
            const localVarPath = `/company/offers/{offerId}/start`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} title 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} contact 
         * @param {number} businessValue 
         * @param {number} deliberationDuration 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTender: async (id: number, title: string, startDate: string, endDate: string, contact: string, businessValue: number, deliberationDuration: number, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putTender', 'id', id)
            // verify required parameter 'title' is not null or undefined
            assertParamExists('putTender', 'title', title)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('putTender', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('putTender', 'endDate', endDate)
            // verify required parameter 'contact' is not null or undefined
            assertParamExists('putTender', 'contact', contact)
            // verify required parameter 'businessValue' is not null or undefined
            assertParamExists('putTender', 'businessValue', businessValue)
            // verify required parameter 'deliberationDuration' is not null or undefined
            assertParamExists('putTender', 'deliberationDuration', deliberationDuration)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putTender', 'body', body)
            const localVarPath = `/company/tenders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (contact !== undefined) {
                localVarQueryParameter['contact'] = contact;
            }

            if (businessValue !== undefined) {
                localVarQueryParameter['businessValue'] = businessValue;
            }

            if (deliberationDuration !== undefined) {
                localVarQueryParameter['deliberationDuration'] = deliberationDuration;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTenderEndDate: async (id: number, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putTenderEndDate', 'id', id)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('putTenderEndDate', 'endDate', endDate)
            const localVarPath = `/company/tenders/{id}/enddate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTenderInvite: async (id: number, email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putTenderInvite', 'id', id)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('putTenderInvite', 'email', email)
            const localVarPath = `/company/tenders/{id}/invite`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTenderState: async (id: number, state: 'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putTenderState', 'id', id)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('putTenderState', 'state', state)
            const localVarPath = `/company/tenders/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile1: async (inlineObject3?: InlineObject3, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTenderDocument: async (id: number, inlineObject4?: InlineObject4, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadTenderDocument', 'id', id)
            const localVarPath = `/company/tenders/{id}/upload`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyControllerApi - functional programming interface
 * @export
 */
export const CompanyControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompany(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTender(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTender(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTenderDocument(id: number, documentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenderResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTenderDocument(id, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTenderDocument(id: number, documentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTenderDocument(id, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTenderLawyerOfferDocuments(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTenderLawyerOfferDocuments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<LawyerActivityResource>} lawyerActivityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLawyers(lawyerActivityResource: Array<LawyerActivityResource>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawyerEntityResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLawyers(lawyerActivityResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTender(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenderResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTender(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenderDocuments(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenderDocumentResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenderDocuments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenderInvitesPaginated(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageInviteResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenderInvitesPaginated(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenderLawyers(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawyerEntityResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenderLawyers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenderNextStates(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenderNextStates(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenderOffers(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawyerOfferResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenderOffers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenderOffersTransitions(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PairLAWYEROFFERSTATEListLAWYEROFFERSTATE>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenderOffersTransitions(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'>} [tenderStates] 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTendersPaginated(tenderStates?: Array<'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTenderResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTendersPaginated(tenderStates, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async me1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.me1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRequestapproval(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRequestapproval(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'PITCH' | 'PROPOSAL'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTender(type: 'PITCH' | 'PROPOSAL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenderResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTender(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LawyerEntityResource} lawyerEntityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTenderAddLawyer(id: number, lawyerEntityResource: LawyerEntityResource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenderResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTenderAddLawyer(id, lawyerEntityResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LawyerEntityResource} lawyerEntityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTenderRemoveLawyer(id: number, lawyerEntityResource: LawyerEntityResource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenderResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTenderRemoveLawyer(id, lawyerEntityResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCancelOffer(offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerOfferResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCancelOffer(offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} ratingQuality 
         * @param {number} ratingPunctuality 
         * @param {number} ratingCommunication 
         * @param {number} ratingPrice 
         * @param {string} review 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCloseMandate(id: number, ratingQuality: number, ratingPunctuality: number, ratingCommunication: number, ratingPrice: number, review: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenderResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCloseMandate(id, ratingQuality, ratingPunctuality, ratingCommunication, ratingPrice, review, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {number} [phone] 
         * @param {string} [picture] 
         * @param {string} [hero] 
         * @param {'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'} [title] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [headline] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDetails(name: string, phone?: number, picture?: string, hero?: string, title?: 'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES', firstName?: string, lastName?: string, headline?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDetails(name, phone, picture, hero, title, firstName, lastName, headline, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'BOARD_MEMBER' | 'BUSINESS_EXECUTIVE' | 'AUTHORIZED_REPRESENTATIVE' | 'GENERAL_COUNSEL' | 'SENIOR_LEGAL_COUNSEL' | 'LEGAL_COUNSEL' | 'OTHER'} position 
         * @param {'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'} [title] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putIdentityDetails1(position: 'BOARD_MEMBER' | 'BUSINESS_EXECUTIVE' | 'AUTHORIZED_REPRESENTATIVE' | 'GENERAL_COUNSEL' | 'SENIOR_LEGAL_COUNSEL' | 'LEGAL_COUNSEL' | 'OTHER', title?: 'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES', firstName?: string, lastName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putIdentityDetails1(position, title, firstName, lastName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putInviteToPitch(offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerOfferResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putInviteToPitch(offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offerId 
         * @param {'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLawyerOfferState(offerId: number, state: 'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerOfferResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLawyerOfferState(offerId, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putRejectOffer(offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerOfferResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putRejectOffer(offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putShortListOffer(offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerOfferResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putShortListOffer(offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putStartOffer(offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenderResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putStartOffer(offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} title 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} contact 
         * @param {number} businessValue 
         * @param {number} deliberationDuration 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTender(id: number, title: string, startDate: string, endDate: string, contact: string, businessValue: number, deliberationDuration: number, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenderResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTender(id, title, startDate, endDate, contact, businessValue, deliberationDuration, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTenderEndDate(id: number, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenderResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTenderEndDate(id, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTenderInvite(id: number, email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTenderInvite(id, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTenderState(id: number, state: 'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenderResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTenderState(id, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile1(inlineObject3?: InlineObject3, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile1(inlineObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadTenderDocument(id: number, inlineObject4?: InlineObject4, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenderResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadTenderDocument(id, inlineObject4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyControllerApi - factory interface
 * @export
 */
export const CompanyControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(options?: any): AxiosPromise<CompanyEntityResource> {
            return localVarFp.createCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTender(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteTender(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenderDocument(id: number, documentId: number, options?: any): AxiosPromise<TenderResource> {
            return localVarFp.deleteTenderDocument(id, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTenderDocument(id: number, documentId: number, options?: any): AxiosPromise<any> {
            return localVarFp.downloadTenderDocument(id, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTenderLawyerOfferDocuments(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.downloadTenderLawyerOfferDocuments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<LawyerActivityResource>} lawyerActivityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawyers(lawyerActivityResource: Array<LawyerActivityResource>, options?: any): AxiosPromise<Array<LawyerEntityResource>> {
            return localVarFp.getLawyers(lawyerActivityResource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTender(id: number, options?: any): AxiosPromise<TenderResource> {
            return localVarFp.getTender(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenderDocuments(id: number, options?: any): AxiosPromise<Array<TenderDocumentResource>> {
            return localVarFp.getTenderDocuments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenderInvitesPaginated(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageInviteResource> {
            return localVarFp.getTenderInvitesPaginated(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenderLawyers(id: number, options?: any): AxiosPromise<Array<LawyerEntityResource>> {
            return localVarFp.getTenderLawyers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenderNextStates(id: number, options?: any): AxiosPromise<Set<string>> {
            return localVarFp.getTenderNextStates(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenderOffers(id: number, options?: any): AxiosPromise<Array<LawyerOfferResource>> {
            return localVarFp.getTenderOffers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenderOffersTransitions(id: number, options?: any): AxiosPromise<Array<PairLAWYEROFFERSTATEListLAWYEROFFERSTATE>> {
            return localVarFp.getTenderOffersTransitions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'>} [tenderStates] 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTendersPaginated(tenderStates?: Array<'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTenderResource> {
            return localVarFp.getTendersPaginated(tenderStates, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me1(options?: any): AxiosPromise<CompanyEntityResource> {
            return localVarFp.me1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestapproval(options?: any): AxiosPromise<CompanyEntityResource> {
            return localVarFp.postRequestapproval(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'PITCH' | 'PROPOSAL'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTender(type: 'PITCH' | 'PROPOSAL', options?: any): AxiosPromise<TenderResource> {
            return localVarFp.postTender(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LawyerEntityResource} lawyerEntityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTenderAddLawyer(id: number, lawyerEntityResource: LawyerEntityResource, options?: any): AxiosPromise<TenderResource> {
            return localVarFp.postTenderAddLawyer(id, lawyerEntityResource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LawyerEntityResource} lawyerEntityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTenderRemoveLawyer(id: number, lawyerEntityResource: LawyerEntityResource, options?: any): AxiosPromise<TenderResource> {
            return localVarFp.postTenderRemoveLawyer(id, lawyerEntityResource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCancelOffer(offerId: number, options?: any): AxiosPromise<LawyerOfferResource> {
            return localVarFp.putCancelOffer(offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} ratingQuality 
         * @param {number} ratingPunctuality 
         * @param {number} ratingCommunication 
         * @param {number} ratingPrice 
         * @param {string} review 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCloseMandate(id: number, ratingQuality: number, ratingPunctuality: number, ratingCommunication: number, ratingPrice: number, review: string, options?: any): AxiosPromise<TenderResource> {
            return localVarFp.putCloseMandate(id, ratingQuality, ratingPunctuality, ratingCommunication, ratingPrice, review, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {number} [phone] 
         * @param {string} [picture] 
         * @param {string} [hero] 
         * @param {'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'} [title] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [headline] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDetails(name: string, phone?: number, picture?: string, hero?: string, title?: 'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES', firstName?: string, lastName?: string, headline?: string, options?: any): AxiosPromise<CompanyEntityResource> {
            return localVarFp.putDetails(name, phone, picture, hero, title, firstName, lastName, headline, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'BOARD_MEMBER' | 'BUSINESS_EXECUTIVE' | 'AUTHORIZED_REPRESENTATIVE' | 'GENERAL_COUNSEL' | 'SENIOR_LEGAL_COUNSEL' | 'LEGAL_COUNSEL' | 'OTHER'} position 
         * @param {'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'} [title] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIdentityDetails1(position: 'BOARD_MEMBER' | 'BUSINESS_EXECUTIVE' | 'AUTHORIZED_REPRESENTATIVE' | 'GENERAL_COUNSEL' | 'SENIOR_LEGAL_COUNSEL' | 'LEGAL_COUNSEL' | 'OTHER', title?: 'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES', firstName?: string, lastName?: string, options?: any): AxiosPromise<CompanyEntityResource> {
            return localVarFp.putIdentityDetails1(position, title, firstName, lastName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInviteToPitch(offerId: number, options?: any): AxiosPromise<LawyerOfferResource> {
            return localVarFp.putInviteToPitch(offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offerId 
         * @param {'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLawyerOfferState(offerId: number, state: 'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED', options?: any): AxiosPromise<LawyerOfferResource> {
            return localVarFp.putLawyerOfferState(offerId, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRejectOffer(offerId: number, options?: any): AxiosPromise<LawyerOfferResource> {
            return localVarFp.putRejectOffer(offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putShortListOffer(offerId: number, options?: any): AxiosPromise<LawyerOfferResource> {
            return localVarFp.putShortListOffer(offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStartOffer(offerId: number, options?: any): AxiosPromise<TenderResource> {
            return localVarFp.putStartOffer(offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} title 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} contact 
         * @param {number} businessValue 
         * @param {number} deliberationDuration 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTender(id: number, title: string, startDate: string, endDate: string, contact: string, businessValue: number, deliberationDuration: number, body: string, options?: any): AxiosPromise<TenderResource> {
            return localVarFp.putTender(id, title, startDate, endDate, contact, businessValue, deliberationDuration, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTenderEndDate(id: number, endDate: string, options?: any): AxiosPromise<TenderResource> {
            return localVarFp.putTenderEndDate(id, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTenderInvite(id: number, email: string, options?: any): AxiosPromise<InviteResource> {
            return localVarFp.putTenderInvite(id, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTenderState(id: number, state: 'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE', options?: any): AxiosPromise<TenderResource> {
            return localVarFp.putTenderState(id, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile1(inlineObject3?: InlineObject3, options?: any): AxiosPromise<string> {
            return localVarFp.uploadFile1(inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTenderDocument(id: number, inlineObject4?: InlineObject4, options?: any): AxiosPromise<TenderResource> {
            return localVarFp.uploadTenderDocument(id, inlineObject4, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyControllerApi - object-oriented interface
 * @export
 * @class CompanyControllerApi
 * @extends {BaseAPI}
 */
export class CompanyControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public createCompany(options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).createCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public deleteTender(id: number, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).deleteTender(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public deleteTenderDocument(id: number, documentId: number, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).deleteTenderDocument(id, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public downloadTenderDocument(id: number, documentId: number, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).downloadTenderDocument(id, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public downloadTenderLawyerOfferDocuments(id: number, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).downloadTenderLawyerOfferDocuments(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<LawyerActivityResource>} lawyerActivityResource 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getLawyers(lawyerActivityResource: Array<LawyerActivityResource>, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getLawyers(lawyerActivityResource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getTender(id: number, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getTender(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getTenderDocuments(id: number, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getTenderDocuments(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [page] Page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getTenderInvitesPaginated(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getTenderInvitesPaginated(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getTenderLawyers(id: number, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getTenderLawyers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getTenderNextStates(id: number, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getTenderNextStates(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getTenderOffers(id: number, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getTenderOffers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getTenderOffersTransitions(id: number, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getTenderOffersTransitions(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'>} [tenderStates] 
     * @param {number} [page] Page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getTendersPaginated(tenderStates?: Array<'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getTendersPaginated(tenderStates, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public me1(options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).me1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public postRequestapproval(options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).postRequestapproval(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'PITCH' | 'PROPOSAL'} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public postTender(type: 'PITCH' | 'PROPOSAL', options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).postTender(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LawyerEntityResource} lawyerEntityResource 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public postTenderAddLawyer(id: number, lawyerEntityResource: LawyerEntityResource, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).postTenderAddLawyer(id, lawyerEntityResource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LawyerEntityResource} lawyerEntityResource 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public postTenderRemoveLawyer(id: number, lawyerEntityResource: LawyerEntityResource, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).postTenderRemoveLawyer(id, lawyerEntityResource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public putCancelOffer(offerId: number, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).putCancelOffer(offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} ratingQuality 
     * @param {number} ratingPunctuality 
     * @param {number} ratingCommunication 
     * @param {number} ratingPrice 
     * @param {string} review 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public putCloseMandate(id: number, ratingQuality: number, ratingPunctuality: number, ratingCommunication: number, ratingPrice: number, review: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).putCloseMandate(id, ratingQuality, ratingPunctuality, ratingCommunication, ratingPrice, review, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {number} [phone] 
     * @param {string} [picture] 
     * @param {string} [hero] 
     * @param {'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'} [title] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [headline] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public putDetails(name: string, phone?: number, picture?: string, hero?: string, title?: 'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES', firstName?: string, lastName?: string, headline?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).putDetails(name, phone, picture, hero, title, firstName, lastName, headline, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'BOARD_MEMBER' | 'BUSINESS_EXECUTIVE' | 'AUTHORIZED_REPRESENTATIVE' | 'GENERAL_COUNSEL' | 'SENIOR_LEGAL_COUNSEL' | 'LEGAL_COUNSEL' | 'OTHER'} position 
     * @param {'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'} [title] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public putIdentityDetails1(position: 'BOARD_MEMBER' | 'BUSINESS_EXECUTIVE' | 'AUTHORIZED_REPRESENTATIVE' | 'GENERAL_COUNSEL' | 'SENIOR_LEGAL_COUNSEL' | 'LEGAL_COUNSEL' | 'OTHER', title?: 'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES', firstName?: string, lastName?: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).putIdentityDetails1(position, title, firstName, lastName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public putInviteToPitch(offerId: number, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).putInviteToPitch(offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} offerId 
     * @param {'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'} state 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public putLawyerOfferState(offerId: number, state: 'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED', options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).putLawyerOfferState(offerId, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public putRejectOffer(offerId: number, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).putRejectOffer(offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public putShortListOffer(offerId: number, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).putShortListOffer(offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public putStartOffer(offerId: number, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).putStartOffer(offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} title 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {string} contact 
     * @param {number} businessValue 
     * @param {number} deliberationDuration 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public putTender(id: number, title: string, startDate: string, endDate: string, contact: string, businessValue: number, deliberationDuration: number, body: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).putTender(id, title, startDate, endDate, contact, businessValue, deliberationDuration, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public putTenderEndDate(id: number, endDate: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).putTenderEndDate(id, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public putTenderInvite(id: number, email: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).putTenderInvite(id, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'} state 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public putTenderState(id: number, state: 'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE', options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).putTenderState(id, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject3} [inlineObject3] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public uploadFile1(inlineObject3?: InlineObject3, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).uploadFile1(inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {InlineObject4} [inlineObject4] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public uploadTenderDocument(id: number, inlineObject4?: InlineObject4, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).uploadTenderDocument(id, inlineObject4, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LawyerControllerApi - axios parameter creator
 * @export
 */
export const LawyerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} issuer 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDiploma: async (issuer: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuer' is not null or undefined
            assertParamExists('addDiploma', 'issuer', issuer)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('addDiploma', 'date', date)
            const localVarPath = `/lawyer/diplomas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (issuer !== undefined) {
                localVarQueryParameter['issuer'] = issuer;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} city 
         * @param {'FIRST_JURIDICAL_EXAMINATION' | 'SECOND_JURIDICAL_EXAMINATION' | 'MASTER_OF_LAWS'} title 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDiplomaIssuer: async (name: string, city: string, title: 'FIRST_JURIDICAL_EXAMINATION' | 'SECOND_JURIDICAL_EXAMINATION' | 'MASTER_OF_LAWS', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('addDiplomaIssuer', 'name', name)
            // verify required parameter 'city' is not null or undefined
            assertParamExists('addDiplomaIssuer', 'city', city)
            // verify required parameter 'title' is not null or undefined
            assertParamExists('addDiplomaIssuer', 'title', title)
            const localVarPath = `/lawyer/diplomaIssuers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLawyer: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lawyer/lawyer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiploma: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDiploma', 'id', id)
            const localVarPath = `/lawyer/diplomas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLawyerOfferDocument: async (id: number, documentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLawyerOfferDocument', 'id', id)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteLawyerOfferDocument', 'documentId', documentId)
            const localVarPath = `/lawyer/offers/{id}/documents/{documentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismissOffer: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dismissOffer', 'id', id)
            const localVarPath = `/lawyer/offer/dismiss/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTenderDocuments: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadTenderDocuments', 'id', id)
            const localVarPath = `/lawyer/offer/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawyerOffer: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLawyerOffer', 'id', id)
            const localVarPath = `/lawyer/offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawyerOfferDocuments: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLawyerOfferDocuments', 'id', id)
            const localVarPath = `/lawyer/offers/{id}/documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'>} [offerStates] 
         * @param {Array<'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'>} [tenderStates] 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawyerOffersPaginated: async (offerStates?: Array<'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'>, tenderStates?: Array<'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'>, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lawyer/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offerStates) {
                localVarQueryParameter['offerStates'] = offerStates;
            }

            if (tenderStates) {
                localVarQueryParameter['tenderStates'] = tenderStates;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawyerOffersSelectedPaginated: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lawyer/offers/selected`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lawyer/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyName 
         * @param {string} companyAddress1 
         * @param {number} companyAddressZipCode 
         * @param {string} companyAddressCity 
         * @param {'DE'} companyAddressCountry 
         * @param {number} companyPhone 
         * @param {string} [companyAddress2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompany: async (companyName: string, companyAddress1: string, companyAddressZipCode: number, companyAddressCity: string, companyAddressCountry: 'DE', companyPhone: number, companyAddress2?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyName' is not null or undefined
            assertParamExists('postCompany', 'companyName', companyName)
            // verify required parameter 'companyAddress1' is not null or undefined
            assertParamExists('postCompany', 'companyAddress1', companyAddress1)
            // verify required parameter 'companyAddressZipCode' is not null or undefined
            assertParamExists('postCompany', 'companyAddressZipCode', companyAddressZipCode)
            // verify required parameter 'companyAddressCity' is not null or undefined
            assertParamExists('postCompany', 'companyAddressCity', companyAddressCity)
            // verify required parameter 'companyAddressCountry' is not null or undefined
            assertParamExists('postCompany', 'companyAddressCountry', companyAddressCountry)
            // verify required parameter 'companyPhone' is not null or undefined
            assertParamExists('postCompany', 'companyPhone', companyPhone)
            const localVarPath = `/lawyer/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyName !== undefined) {
                localVarQueryParameter['companyName'] = companyName;
            }

            if (companyAddress1 !== undefined) {
                localVarQueryParameter['companyAddress1'] = companyAddress1;
            }

            if (companyAddress2 !== undefined) {
                localVarQueryParameter['companyAddress2'] = companyAddress2;
            }

            if (companyAddressZipCode !== undefined) {
                localVarQueryParameter['companyAddressZipCode'] = companyAddressZipCode;
            }

            if (companyAddressCity !== undefined) {
                localVarQueryParameter['companyAddressCity'] = companyAddressCity;
            }

            if (companyAddressCountry !== undefined) {
                localVarQueryParameter['companyAddressCountry'] = companyAddressCountry;
            }

            if (companyPhone !== undefined) {
                localVarQueryParameter['companyPhone'] = companyPhone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<LawyerActivityResource>} lawyerActivityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyActivities: async (lawyerActivityResource: Array<LawyerActivityResource>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lawyerActivityResource' is not null or undefined
            assertParamExists('postCompanyActivities', 'lawyerActivityResource', lawyerActivityResource)
            const localVarPath = `/lawyer/company/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lawyerActivityResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<LawyerSpecialityResource>} lawyerSpecialityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyCertifiedSpecialities: async (lawyerSpecialityResource: Array<LawyerSpecialityResource>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lawyerSpecialityResource' is not null or undefined
            assertParamExists('postCompanyCertifiedSpecialities', 'lawyerSpecialityResource', lawyerSpecialityResource)
            const localVarPath = `/lawyer/company/certifiedspecialities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lawyerSpecialityResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<LawyerSpecialityResource>} lawyerSpecialityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanySpecialities: async (lawyerSpecialityResource: Array<LawyerSpecialityResource>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lawyerSpecialityResource' is not null or undefined
            assertParamExists('postCompanySpecialities', 'lawyerSpecialityResource', lawyerSpecialityResource)
            const localVarPath = `/lawyer/company/specialities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lawyerSpecialityResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<LawyerLanguageResource>} lawyerLanguageResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLanguages: async (lawyerLanguageResource: Array<LawyerLanguageResource>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lawyerLanguageResource' is not null or undefined
            assertParamExists('postLanguages', 'lawyerLanguageResource', lawyerLanguageResource)
            const localVarPath = `/lawyer/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lawyerLanguageResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestApproval: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lawyer/requestapproval`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishOffer: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publishOffer', 'id', id)
            const localVarPath = `/lawyer/offer/{id}/publish/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'} [title] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIdentityDetails: async (title?: 'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES', firstName?: string, lastName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lawyer/identitydetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'} title 
         * @param {string} firstName 
         * @param {string} lastName 
         * @param {string} dateStartActivity 
         * @param {string} [picture] 
         * @param {string} [hero] 
         * @param {string} [headline] 
         * @param {string} [aboutMe] 
         * @param {string} [references] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLawyerDetails: async (title: 'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES', firstName: string, lastName: string, dateStartActivity: string, picture?: string, hero?: string, headline?: string, aboutMe?: string, references?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'title' is not null or undefined
            assertParamExists('putLawyerDetails', 'title', title)
            // verify required parameter 'firstName' is not null or undefined
            assertParamExists('putLawyerDetails', 'firstName', firstName)
            // verify required parameter 'lastName' is not null or undefined
            assertParamExists('putLawyerDetails', 'lastName', lastName)
            // verify required parameter 'dateStartActivity' is not null or undefined
            assertParamExists('putLawyerDetails', 'dateStartActivity', dateStartActivity)
            const localVarPath = `/lawyer/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (picture !== undefined) {
                localVarQueryParameter['picture'] = picture;
            }

            if (hero !== undefined) {
                localVarQueryParameter['hero'] = hero;
            }

            if (headline !== undefined) {
                localVarQueryParameter['headline'] = headline;
            }

            if (aboutMe !== undefined) {
                localVarQueryParameter['aboutMe'] = aboutMe;
            }

            if (references !== undefined) {
                localVarQueryParameter['references'] = references;
            }

            if (dateStartActivity !== undefined) {
                localVarQueryParameter['dateStartActivity'] = (dateStartActivity as any instanceof Date) ?
                    (dateStartActivity as any).toISOString() :
                    dateStartActivity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} comment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLawyerOfferReviewComment: async (id: number, comment: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putLawyerOfferReviewComment', 'id', id)
            // verify required parameter 'comment' is not null or undefined
            assertParamExists('putLawyerOfferReviewComment', 'comment', comment)
            const localVarPath = `/lawyer/offers/{id}/reviewComment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} businessValue 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOffer: async (id: number, businessValue: number, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putOffer', 'id', id)
            // verify required parameter 'businessValue' is not null or undefined
            assertParamExists('putOffer', 'businessValue', businessValue)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putOffer', 'body', body)
            const localVarPath = `/lawyer/offer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (businessValue !== undefined) {
                localVarQueryParameter['businessValue'] = businessValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument: async (inlineObject1?: InlineObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lawyer/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (inlineObject?: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lawyer/uploadfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLawyerOfferDocument: async (id: number, inlineObject2?: InlineObject2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadLawyerOfferDocument', 'id', id)
            const localVarPath = `/lawyer/offers/{id}/upload`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LawyerControllerApi - functional programming interface
 * @export
 */
export const LawyerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LawyerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} issuer 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDiploma(issuer: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDiploma(issuer, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} city 
         * @param {'FIRST_JURIDICAL_EXAMINATION' | 'SECOND_JURIDICAL_EXAMINATION' | 'MASTER_OF_LAWS'} title 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDiplomaIssuer(name: string, city: string, title: 'FIRST_JURIDICAL_EXAMINATION' | 'SECOND_JURIDICAL_EXAMINATION' | 'MASTER_OF_LAWS', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiplomaIssuerResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDiplomaIssuer(name, city, title, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLawyer(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLawyer(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDiploma(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDiploma(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLawyerOfferDocument(id: number, documentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerOfferResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLawyerOfferDocument(id, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dismissOffer(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerOfferResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dismissOffer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTenderDocuments(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTenderDocuments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLawyerOffer(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerOfferResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLawyerOffer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLawyerOfferDocuments(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawyerOfferDocumentResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLawyerOfferDocuments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'>} [offerStates] 
         * @param {Array<'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'>} [tenderStates] 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLawyerOffersPaginated(offerStates?: Array<'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'>, tenderStates?: Array<'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLawyerOfferResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLawyerOffersPaginated(offerStates, tenderStates, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLawyerOffersSelectedPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLawyerOfferResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLawyerOffersSelectedPaginated(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async me(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.me(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyName 
         * @param {string} companyAddress1 
         * @param {number} companyAddressZipCode 
         * @param {string} companyAddressCity 
         * @param {'DE'} companyAddressCountry 
         * @param {number} companyPhone 
         * @param {string} [companyAddress2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompany(companyName: string, companyAddress1: string, companyAddressZipCode: number, companyAddressCity: string, companyAddressCountry: 'DE', companyPhone: number, companyAddress2?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompany(companyName, companyAddress1, companyAddressZipCode, companyAddressCity, companyAddressCountry, companyPhone, companyAddress2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<LawyerActivityResource>} lawyerActivityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompanyActivities(lawyerActivityResource: Array<LawyerActivityResource>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawyerActivityResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompanyActivities(lawyerActivityResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<LawyerSpecialityResource>} lawyerSpecialityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompanyCertifiedSpecialities(lawyerSpecialityResource: Array<LawyerSpecialityResource>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawyerSpecialityResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompanyCertifiedSpecialities(lawyerSpecialityResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<LawyerSpecialityResource>} lawyerSpecialityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompanySpecialities(lawyerSpecialityResource: Array<LawyerSpecialityResource>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawyerSpecialityResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompanySpecialities(lawyerSpecialityResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<LawyerLanguageResource>} lawyerLanguageResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLanguages(lawyerLanguageResource: Array<LawyerLanguageResource>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawyerLanguageResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLanguages(lawyerLanguageResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRequestApproval(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRequestApproval(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishOffer(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerOfferResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishOffer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'} [title] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putIdentityDetails(title?: 'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES', firstName?: string, lastName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putIdentityDetails(title, firstName, lastName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'} title 
         * @param {string} firstName 
         * @param {string} lastName 
         * @param {string} dateStartActivity 
         * @param {string} [picture] 
         * @param {string} [hero] 
         * @param {string} [headline] 
         * @param {string} [aboutMe] 
         * @param {string} [references] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLawyerDetails(title: 'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES', firstName: string, lastName: string, dateStartActivity: string, picture?: string, hero?: string, headline?: string, aboutMe?: string, references?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLawyerDetails(title, firstName, lastName, dateStartActivity, picture, hero, headline, aboutMe, references, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} comment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLawyerOfferReviewComment(id: number, comment: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerOfferResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLawyerOfferReviewComment(id, comment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} businessValue 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putOffer(id: number, businessValue: number, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerOfferResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putOffer(id, businessValue, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDocument(inlineObject1?: InlineObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerEntityResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDocument(inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(inlineObject?: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadLawyerOfferDocument(id: number, inlineObject2?: InlineObject2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawyerOfferResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLawyerOfferDocument(id, inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LawyerControllerApi - factory interface
 * @export
 */
export const LawyerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LawyerControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} issuer 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDiploma(issuer: number, date: string, options?: any): AxiosPromise<LawyerEntityResource> {
            return localVarFp.addDiploma(issuer, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} city 
         * @param {'FIRST_JURIDICAL_EXAMINATION' | 'SECOND_JURIDICAL_EXAMINATION' | 'MASTER_OF_LAWS'} title 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDiplomaIssuer(name: string, city: string, title: 'FIRST_JURIDICAL_EXAMINATION' | 'SECOND_JURIDICAL_EXAMINATION' | 'MASTER_OF_LAWS', options?: any): AxiosPromise<Array<DiplomaIssuerResource>> {
            return localVarFp.addDiplomaIssuer(name, city, title, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLawyer(options?: any): AxiosPromise<LawyerEntityResource> {
            return localVarFp.createLawyer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiploma(id: number, options?: any): AxiosPromise<LawyerEntityResource> {
            return localVarFp.deleteDiploma(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLawyerOfferDocument(id: number, documentId: number, options?: any): AxiosPromise<LawyerOfferResource> {
            return localVarFp.deleteLawyerOfferDocument(id, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismissOffer(id: number, options?: any): AxiosPromise<LawyerOfferResource> {
            return localVarFp.dismissOffer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTenderDocuments(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.downloadTenderDocuments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawyerOffer(id: number, options?: any): AxiosPromise<LawyerOfferResource> {
            return localVarFp.getLawyerOffer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawyerOfferDocuments(id: number, options?: any): AxiosPromise<Array<LawyerOfferDocumentResource>> {
            return localVarFp.getLawyerOfferDocuments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'>} [offerStates] 
         * @param {Array<'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'>} [tenderStates] 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawyerOffersPaginated(offerStates?: Array<'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'>, tenderStates?: Array<'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLawyerOfferResource> {
            return localVarFp.getLawyerOffersPaginated(offerStates, tenderStates, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawyerOffersSelectedPaginated(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLawyerOfferResource> {
            return localVarFp.getLawyerOffersSelectedPaginated(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me(options?: any): AxiosPromise<LawyerEntityResource> {
            return localVarFp.me(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyName 
         * @param {string} companyAddress1 
         * @param {number} companyAddressZipCode 
         * @param {string} companyAddressCity 
         * @param {'DE'} companyAddressCountry 
         * @param {number} companyPhone 
         * @param {string} [companyAddress2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompany(companyName: string, companyAddress1: string, companyAddressZipCode: number, companyAddressCity: string, companyAddressCountry: 'DE', companyPhone: number, companyAddress2?: string, options?: any): AxiosPromise<LawyerEntityResource> {
            return localVarFp.postCompany(companyName, companyAddress1, companyAddressZipCode, companyAddressCity, companyAddressCountry, companyPhone, companyAddress2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<LawyerActivityResource>} lawyerActivityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyActivities(lawyerActivityResource: Array<LawyerActivityResource>, options?: any): AxiosPromise<Array<LawyerActivityResource>> {
            return localVarFp.postCompanyActivities(lawyerActivityResource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<LawyerSpecialityResource>} lawyerSpecialityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyCertifiedSpecialities(lawyerSpecialityResource: Array<LawyerSpecialityResource>, options?: any): AxiosPromise<Array<LawyerSpecialityResource>> {
            return localVarFp.postCompanyCertifiedSpecialities(lawyerSpecialityResource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<LawyerSpecialityResource>} lawyerSpecialityResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanySpecialities(lawyerSpecialityResource: Array<LawyerSpecialityResource>, options?: any): AxiosPromise<Array<LawyerSpecialityResource>> {
            return localVarFp.postCompanySpecialities(lawyerSpecialityResource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<LawyerLanguageResource>} lawyerLanguageResource 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLanguages(lawyerLanguageResource: Array<LawyerLanguageResource>, options?: any): AxiosPromise<Array<LawyerLanguageResource>> {
            return localVarFp.postLanguages(lawyerLanguageResource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestApproval(options?: any): AxiosPromise<LawyerEntityResource> {
            return localVarFp.postRequestApproval(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishOffer(id: number, options?: any): AxiosPromise<LawyerOfferResource> {
            return localVarFp.publishOffer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'} [title] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIdentityDetails(title?: 'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES', firstName?: string, lastName?: string, options?: any): AxiosPromise<LawyerEntityResource> {
            return localVarFp.putIdentityDetails(title, firstName, lastName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'} title 
         * @param {string} firstName 
         * @param {string} lastName 
         * @param {string} dateStartActivity 
         * @param {string} [picture] 
         * @param {string} [hero] 
         * @param {string} [headline] 
         * @param {string} [aboutMe] 
         * @param {string} [references] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLawyerDetails(title: 'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES', firstName: string, lastName: string, dateStartActivity: string, picture?: string, hero?: string, headline?: string, aboutMe?: string, references?: string, options?: any): AxiosPromise<LawyerEntityResource> {
            return localVarFp.putLawyerDetails(title, firstName, lastName, dateStartActivity, picture, hero, headline, aboutMe, references, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} comment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLawyerOfferReviewComment(id: number, comment: string, options?: any): AxiosPromise<LawyerOfferResource> {
            return localVarFp.putLawyerOfferReviewComment(id, comment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} businessValue 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOffer(id: number, businessValue: number, body: string, options?: any): AxiosPromise<LawyerOfferResource> {
            return localVarFp.putOffer(id, businessValue, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument(inlineObject1?: InlineObject1, options?: any): AxiosPromise<LawyerEntityResource> {
            return localVarFp.uploadDocument(inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(inlineObject?: InlineObject, options?: any): AxiosPromise<string> {
            return localVarFp.uploadFile(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLawyerOfferDocument(id: number, inlineObject2?: InlineObject2, options?: any): AxiosPromise<LawyerOfferResource> {
            return localVarFp.uploadLawyerOfferDocument(id, inlineObject2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LawyerControllerApi - object-oriented interface
 * @export
 * @class LawyerControllerApi
 * @extends {BaseAPI}
 */
export class LawyerControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} issuer 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public addDiploma(issuer: number, date: string, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).addDiploma(issuer, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} city 
     * @param {'FIRST_JURIDICAL_EXAMINATION' | 'SECOND_JURIDICAL_EXAMINATION' | 'MASTER_OF_LAWS'} title 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public addDiplomaIssuer(name: string, city: string, title: 'FIRST_JURIDICAL_EXAMINATION' | 'SECOND_JURIDICAL_EXAMINATION' | 'MASTER_OF_LAWS', options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).addDiplomaIssuer(name, city, title, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public createLawyer(options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).createLawyer(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public deleteDiploma(id: number, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).deleteDiploma(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public deleteLawyerOfferDocument(id: number, documentId: number, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).deleteLawyerOfferDocument(id, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public dismissOffer(id: number, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).dismissOffer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public downloadTenderDocuments(id: number, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).downloadTenderDocuments(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public getLawyerOffer(id: number, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).getLawyerOffer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public getLawyerOfferDocuments(id: number, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).getLawyerOfferDocuments(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'>} [offerStates] 
     * @param {Array<'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'>} [tenderStates] 
     * @param {number} [page] Page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public getLawyerOffersPaginated(offerStates?: Array<'ANSWERED' | 'CANCELLED' | 'INVITED' | 'INVITED_TO_PITCH' | 'NOT_INTERESTED' | 'NOT_SELECTED' | 'SHORT_LIST' | 'SELECTED' | 'CLOSED'>, tenderStates?: Array<'CANCELED' | 'DELETED' | 'DONE' | 'DRAFT' | 'INVITE_SENT' | 'OFFER_SELECTED' | 'DELIBERATE' | 'WAITING_START_DATE'>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).getLawyerOffersPaginated(offerStates, tenderStates, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public getLawyerOffersSelectedPaginated(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).getLawyerOffersSelectedPaginated(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public me(options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).me(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyName 
     * @param {string} companyAddress1 
     * @param {number} companyAddressZipCode 
     * @param {string} companyAddressCity 
     * @param {'DE'} companyAddressCountry 
     * @param {number} companyPhone 
     * @param {string} [companyAddress2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public postCompany(companyName: string, companyAddress1: string, companyAddressZipCode: number, companyAddressCity: string, companyAddressCountry: 'DE', companyPhone: number, companyAddress2?: string, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).postCompany(companyName, companyAddress1, companyAddressZipCode, companyAddressCity, companyAddressCountry, companyPhone, companyAddress2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<LawyerActivityResource>} lawyerActivityResource 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public postCompanyActivities(lawyerActivityResource: Array<LawyerActivityResource>, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).postCompanyActivities(lawyerActivityResource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<LawyerSpecialityResource>} lawyerSpecialityResource 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public postCompanyCertifiedSpecialities(lawyerSpecialityResource: Array<LawyerSpecialityResource>, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).postCompanyCertifiedSpecialities(lawyerSpecialityResource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<LawyerSpecialityResource>} lawyerSpecialityResource 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public postCompanySpecialities(lawyerSpecialityResource: Array<LawyerSpecialityResource>, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).postCompanySpecialities(lawyerSpecialityResource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<LawyerLanguageResource>} lawyerLanguageResource 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public postLanguages(lawyerLanguageResource: Array<LawyerLanguageResource>, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).postLanguages(lawyerLanguageResource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public postRequestApproval(options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).postRequestApproval(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public publishOffer(id: number, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).publishOffer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'} [title] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public putIdentityDetails(title?: 'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES', firstName?: string, lastName?: string, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).putIdentityDetails(title, firstName, lastName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES'} title 
     * @param {string} firstName 
     * @param {string} lastName 
     * @param {string} dateStartActivity 
     * @param {string} [picture] 
     * @param {string} [hero] 
     * @param {string} [headline] 
     * @param {string} [aboutMe] 
     * @param {string} [references] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public putLawyerDetails(title: 'NONE' | 'DOCTOR' | 'PROFESSOR_DOCTOR_UNIVERSITY' | 'PROFESSOR_DOCTOR_UNIVERSITY_OF_APPLIED_SCIENCES', firstName: string, lastName: string, dateStartActivity: string, picture?: string, hero?: string, headline?: string, aboutMe?: string, references?: string, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).putLawyerDetails(title, firstName, lastName, dateStartActivity, picture, hero, headline, aboutMe, references, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} comment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public putLawyerOfferReviewComment(id: number, comment: string, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).putLawyerOfferReviewComment(id, comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} businessValue 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public putOffer(id: number, businessValue: number, body: string, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).putOffer(id, businessValue, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject1} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public uploadDocument(inlineObject1?: InlineObject1, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).uploadDocument(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public uploadFile(inlineObject?: InlineObject, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).uploadFile(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {InlineObject2} [inlineObject2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LawyerControllerApi
     */
    public uploadLawyerOfferDocument(id: number, inlineObject2?: InlineObject2, options?: AxiosRequestConfig) {
        return LawyerControllerApiFp(this.configuration).uploadLawyerOfferDocument(id, inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicControllerApi - axios parameter creator
 * @export
 */
export const PublicControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertifiedSpecialities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/certifiedspecialities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyFile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCompanyFile', 'id', id)
            const localVarPath = `/public/companies/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiplomaIssuers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/diplomaIssuers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawyerFile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLawyerFile', 'id', id)
            const localVarPath = `/public/lawyers/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawyerReviews: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLawyerReviews', 'id', id)
            const localVarPath = `/public/lawyers/{id}/reviews`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/specialities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicControllerApi - functional programming interface
 * @export
 */
export const PublicControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivities(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawyerActivityResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCertifiedSpecialities(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawyerSpecialityResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCertifiedSpecialities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyFile(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDiplomaIssuers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiplomaIssuerResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiplomaIssuers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLanguages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawyerLanguageResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLanguages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLawyerFile(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLawyerFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLawyerReviews(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReviewResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLawyerReviews(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecialities(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawyerSpecialityResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecialities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicControllerApi - factory interface
 * @export
 */
export const PublicControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivities(options?: any): AxiosPromise<Array<LawyerActivityResource>> {
            return localVarFp.getActivities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertifiedSpecialities(options?: any): AxiosPromise<Array<LawyerSpecialityResource>> {
            return localVarFp.getCertifiedSpecialities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyFile(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.getCompanyFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiplomaIssuers(options?: any): AxiosPromise<Array<DiplomaIssuerResource>> {
            return localVarFp.getDiplomaIssuers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguages(options?: any): AxiosPromise<Array<LawyerLanguageResource>> {
            return localVarFp.getLanguages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawyerFile(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.getLawyerFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawyerReviews(id: number, options?: any): AxiosPromise<Array<ReviewResource>> {
            return localVarFp.getLawyerReviews(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialities(options?: any): AxiosPromise<Array<LawyerSpecialityResource>> {
            return localVarFp.getSpecialities(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicControllerApi - object-oriented interface
 * @export
 * @class PublicControllerApi
 * @extends {BaseAPI}
 */
export class PublicControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getActivities(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getActivities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getCertifiedSpecialities(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getCertifiedSpecialities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getCompanyFile(id: number, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getCompanyFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getDiplomaIssuers(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getDiplomaIssuers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getLanguages(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getLanguages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getLawyerFile(id: number, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getLawyerFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getLawyerReviews(id: number, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getLawyerReviews(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getSpecialities(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getSpecialities(options).then((request) => request(this.axios, this.basePath));
    }
}


